<div class="delivery" *ngIf="
     typeContract === 'voice' ||
     typeContract === 'voice_esim' ||
     typeContract === 'voice_wifi' ||
     residenceOnly;
     else datawifi">
  <div class="delivery__pickup__information">
    <h4 class="pickup__title">pick up location</h4>
    <p class="pickup__info">The address written on your photo ID</p>

    <form class="delivery__form" [formGroup]="voiceIncludeFormGroup">
      <div class="form--item pickup__date">
        <label class="label">
          preferred date of pick up
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" (click)="picker.open()">
            <input matInput placeholder="Select date" formControlName="preffered_date_of_pick_up"
              [matDatepicker]="picker" [min]="minDate" readonly/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <p class="delivery__note">
        If you do not specify a desired delivery date, we will deliver your order as possible.
      </p>
      <p class="delivery__note">
        Please note: this is not guaranteed date/time of delivery.<br>
         The decided delivery date will be informed by email after the application screening is complete.
      </p>

      <div class="form--item pickup__location">
        <label class="label">delivery time specify
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" #deliveryTimeSpectifyFF>
            <mat-select disableRipple [placeholder]="'ETA to your pick up place'"
              (selectionChange)="deliveryTimeSpecifySelectEvent($event)"
              formControlName="delivery_time_specify" required>
              <mat-option *ngFor="let time of deliveryTimeSpecify" [value]="time">{{ time }}</mat-option>
            </mat-select>
            <mat-error *ngIf="delivery_time_specify.hasError('required')">Select one</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form__item phone__number">
        <p class="label">contact phone number</p>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field">
            <input matInput placeholder="Enter phone number" formControlName="contact_phone_number" />
            <mat-hint class="hint">Japanese phone number that postman can contact in the case you
              are absent.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="form--item note">
        <label class="label"> note </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <textarea matInput formControlName="note"
              placeholder="Please use this box if you have any question regarding your pick up process."
              maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <app-upload-document
    [subscription_type]="subscription_type"
    [payment_type]="payment_type"
    [pick_up_method]="pick_up_method"
  ></app-upload-document>
  <button class="submit__button submitted" (click)="submitDelivery()">NEXT STEP
  </button>
</div>

<ng-template #datawifi>
  <div class="delivery__data__wifi">
    <div *ngIf="typeContract !== 'home_wifi'" class="delivery__data__wifi__title">
      <label class="label">
        pick up location
        <app-required></app-required>
      </label>
    </div>

    <div *ngIf="typeContract !== 'home_wifi'" class="delivery__data__wifi__content">
      <button class="pickup__button" [class.pickup__location]="isPickupResidence" (click)="pickUpResidence()" #residenceRef>
        Residence / Corporate office / Friends house
      </button>
      <button class="pickup__button" [class.pickup__location]="isPickupHotel" (click)="pickUpHotel()" #hotelRef>
        Hotel
      </button>
      <button class="pickup__button" [class.pickup__location]="isPickupPostOffice" (click)="pickUpPostOffice()" #postOfficeRef>
        Post Offices
      </button>
      <button class="pickup__button" [class.pickup__location]="isPickupOther" (click)="pickUpOther()" #otherRef>
        Other
      </button>
      <div class="pickup-lcoation-error" *ngIf="isPickupLocationSelectOneErr">
        <span >Select one</span>
      </div>

    </div>
    <div class="delivery__pickup__location__content">
      <ng-container *ngIf="isPickupResidence">
        <app-residence (residentFormValid)="residentFormValidChange($event)" [minDate]="minDate"></app-residence>
      </ng-container>
      <ng-container *ngIf="isPickupHotel">
        <app-hotel (formValid)="hotelFormValidChange($event)" [minDate]="minDate"></app-hotel>
      </ng-container>
      <ng-container *ngIf="isPickupPostOffice">
        <app-post-office (postOfficeFormValid)="postOfficeFormValidChange($event)" [minDate]="minDate">
        </app-post-office>
      </ng-container>
      <ng-container *ngIf="isPickupOther">
        <app-other [minDate]="minDate" (otherFormValid)="otherFormValidChange($event)"></app-other>
      </ng-container>
    </div>
    <app-upload-document
    [subscription_type]="subscription_type"
    [payment_type]="payment_type"
    [pick_up_method]="pick_up_method"></app-upload-document>
    <button class="submit__button submitted" (click)="submitDelivery()"> NEXT STEP </button>
  </div>
</ng-template>
