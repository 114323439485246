import { DOCUMENT } from "@angular/common";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { filter } from "rxjs/operators";
import { Constants } from "src/app/constants/constants";
import { HistoryService } from "src/app/services/history.service";
import { LongOrderService } from "src/app/services/long-order.service";
import { SubmitData } from "src/app/types/submit-data-step4";
import { SubSink } from "subsink";
import { RegisterNewCardComponent } from "./register-new-card/register-new-card.component";

@Component({
  selector: "app-confirmation-and-payment",
  templateUrl: "./confirmation-and-payment.component.html",
  styleUrls: ["./confirmation-and-payment.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationAndPaymentComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  private sub = new SubSink();
  private email: string;
  referralCode: string;
  avaibleReferralCode: boolean;
  isReachedMaximumPayment: boolean;
  checkbox: boolean;
  isCheckboxErr: boolean;
  validField: boolean;
  newCardValid: boolean;
  refferralCodeValid: boolean;
  refferralCodeInvalid: boolean;
  refferralCodeField: FormControl;
  paymentMethod: string; // SMARTPIT | CREDIT CARD
  pickupMethod: string;
  pickupData: any;
  dataSubmit: SubmitData;
  loading$ = this.longOrderServices.loading$;
  @ViewChild(RegisterNewCardComponent, { static: false })
  registerNewCardComponent: RegisterNewCardComponent;
  constructor(
    private router: Router,
    private renderer2: Renderer2,
    private longOrderServices: LongOrderService,
    @Inject(DOCUMENT) private document: Document,
    private readonly history: HistoryService,
    private cdt: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refferralCodeValid = false;
    this.refferralCodeInvalid = false;
    this.newCardValid = false;
    // this.isReachedMaximumPayment = false;
    this.createRefferralForm();
    this.addScriptTab();
    this.sub.sink = this.refferralCodeField.valueChanges.subscribe((res) => {
      if (res) {
        this.validField = true;
      } else {
        this.validField = false;
        this.refferralCodeInvalid = false;
        this.refferralCodeValid = false;
      }
    });

    this.sub.sink = this.longOrderServices.paymentMEthod$.subscribe((res) => {
      this.paymentMethod = res;
      console.log("Payment method :::", this.paymentMethod);
      this.dataSubmit = { ...this.dataSubmit, pay_method: res };
    });
    this.sub.sink = this.longOrderServices.contractHash$.subscribe(
      (res) => {
        this.dataSubmit = { ...this.dataSubmit, contract_hash: res }
      }
    );
    this.sub.sink = this.longOrderServices.orderInformation$.subscribe(
      (res) => {
        if (res) {
          const quantity = res.data.quantity;
          const subscription_plan = res.data.subscription_plan;
          const contract_code = res.data.contract_code;
          this.email = res.data.customer.email;
          this.dataSubmit = {
            ...this.dataSubmit,
            contract_code,
            quantity,
            subscription_plan,
          };
        }
      }
    );
    this.sub.sink = this.longOrderServices.isStudentCheck$.subscribe((res) => {
      if (res) {
        this.dataSubmit = {
          ...this.dataSubmit,
          coupon_code: ["#*@STUDENT ID#*@"],
        };
      }
    });
    this.sub.sink = this.longOrderServices.referralCode$
      .pipe(filter((code) => code !== null))
      .subscribe((referralcode) => {
        this.refferralCodeField.patchValue(referralcode);
        this.avaibleReferralCode = true;
      });
  }
  ngAfterViewChecked() {
    if (this.registerNewCardComponent !== undefined) {
      this.newCardValid =
        this.registerNewCardComponent.newCardInformation.valid;
    } else {
      this.newCardValid = true;
    }
    this.cdt.detectChanges();
  }
  ngOnDetroy() {
    this.sub.unsubscribe();
  }
  pickupMethodEvent(pickupMethod: string) {
    this.pickupMethod = pickupMethod; // office airport delivery
    this.dataSubmit = { ...this.dataSubmit, pick_up_method: pickupMethod };
  }
  pickupInformation(pickupContent$: Observable<any>) {
    this.sub.sink = pickupContent$.subscribe((res) => {
      const pickupType = this.pickupMethod;
      if (pickupType === "AIRPORT") {
        this.dataSubmit = {
          ...this.dataSubmit,
          airport_information: res,
        };
      }
      if (pickupType === "OFFICE") {
        this.dataSubmit = {
          ...this.dataSubmit,
          office_information: res,
        };
      }
      if (pickupType === "DELIVERY") {
        this.dataSubmit = {
          ...this.dataSubmit,
          delivery_information: res,
        };
      }
      if (pickupType === "EKYC") {
        this.dataSubmit = {
          ...this.dataSubmit,
          ekyc_information: res,
        };
        console.log('dataSubmit : ',this.dataSubmit);
      }
    });
  }
  checkboxChange(event: boolean) {
    this.checkbox = event;
    this.isCheckboxErr = !event;
  }
  refferralDiscountConfirm() {
    const refferralField = this.refferralCodeField.value;
    this.longOrderServices.RefferalCodeDiscount(refferralField).subscribe(
      (res) => {
        if (res.data) {
          this.refferralCodeValid = true;
          this.refferralCodeInvalid = false;
          this.dataSubmit = {
            ...this.dataSubmit,
            referral_code: res.data.referral_code,
          };
        }
      },
      (err) => {
        this.refferralCodeInvalid = true;
        this.refferralCodeValid = false;
        delete this.dataSubmit.referral_code;
        return; // of(null)
      }
    );
  }
  titleCouponCode(coupon: string) {
    if (!coupon) {
      this.dataSubmit = {
        ...this.dataSubmit,
        coupon_code: [],
      };
    } else {
      this.dataSubmit = {
        ...this.dataSubmit,
        coupon_code: [coupon],
      };
    }
  }
  private async checkCreditCardAvailable(): Promise<boolean> {
    const card_available = await this.longOrderServices
      .creditCardAvailablity(this.email)
      .toPromise();
    console.log("json testing :", JSON.stringify(card_available));
    return card_available;
  }
  async handleSubmitButton() {
    if (this.longOrderServices.getloadingValue()) {
      console.log("Skip : getloadingValue (still processing)");
      return;
    }
    this.longOrderServices.setLoadingEvent = true;

    if (this.paymentMethod === "CREDIT CARD") {
      await this.checkCreditCardAvailable().then(
        (data) => (this.isReachedMaximumPayment = data)
      );
      console.log("Checking card available::", this.isReachedMaximumPayment);
    }
    if (
      this.paymentMethod === "CREDIT CARD" &&
      this.registerNewCardComponent !== undefined &&
      this.isReachedMaximumPayment !== undefined
    ) {
      //
      console.log("credit card running::", this.isReachedMaximumPayment);
      if (this.registerNewCardComponent.newCardInformation.invalid) {
        this.longOrderServices.setLoadingEvent = false;
        this.registerNewCardComponent.showErrorFormField();
        return;
      }
      if (!this.checkbox) {
        this.longOrderServices.setLoadingEvent = false;
        this.isCheckboxErr = true;
        return;
      }
      if (!this.isReachedMaximumPayment) {
        this.longOrderServices.setLoadingEvent = false;
        console.log("start routing:::");
        this.router.navigate(["card-blocked"]);
        return;
      }

      const newCardValue =
        this.registerNewCardComponent.newCardInformation.getRawValue();
      console.log("New Card Information: ----------- ", newCardValue);
      const cardParams = {
        holdername: newCardValue.card_holder_name,
        cardno: newCardValue.card_number.trim().replace(/\s+/g, ""),
        expire: newCardValue.expire_year + newCardValue.expire_month,
        securitycode: newCardValue.card_cvv,
        tokennumber: "1",
      };
      console.log("Card params: -----", cardParams);
      (window as any).Multipayment.init(Constants.SHOP_ID);
      (window as any).Multipayment.getToken(cardParams, (repsonse) => {
        console.log("repsonse from GMO: ------ ", repsonse);
        if (repsonse.resultCode !== "000") {
          this.longOrderServices.setLoadingEvent = false;
          return throwError(repsonse);
        } else {
          this.dataSubmit = {
            ...this.dataSubmit,
            card_information: {
              token: repsonse.tokenObject.token,
              holderName: newCardValue.card_holder_name,
              gmoMemberId: this.email,
            },
            use_registered_card: false,
          };
          this.longOrderServices.SubmittedOrder(this.dataSubmit, this.email);
        }
      });
    }
    if (this.paymentMethod === "SMARTPIT") {
      console.log("case smartpit running::");
      if (!this.checkbox) {
        this.longOrderServices.setLoadingEvent = false;
        this.isCheckboxErr = true;
        return;
      }
      this.longOrderServices.SubmittedOrder(this.dataSubmit, this.email);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  private createRefferralForm() {
    this.refferralCodeField = new FormControl(
      "",
      Validators.compose([
        Validators.maxLength(8),
        Validators.pattern(/^[0-9]+/),
      ])
    );
  }

  private addScriptTab() {
    const script = this.renderer2.createElement("script");
    script.type = "text/javascript";
    script.src = Constants.GMO_PAYMENT_GATEWAY;
    script.text = "";
    this.renderer2.appendChild(this.document.body, script);
  }
}
