<div class="other__pickup">
  <form class="other__pickup__form" [formGroup]="otherFG">
    <div class="form__item address__input">
      <label class="label">address<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #otherAddressRef>
          <input matInput formControlName="shipping_address" (focus)="otherAddressChange($event)" required />
          <mat-error *ngIf="shipping_address.hasError('required')">Required *</mat-error>
        </mat-form-field>
        <p class="hint__text"
        [ngClass]="{'pd-top': (shipping_address.touched || shipping_address.dirty) && shipping_address.invalid}"
        >
          Please provide the
          full address including the building name and room number (if available).
        </p>
      </div>
    </div>

    <div class="form__item">
      <label class="label">preferred date of pick up<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #otherPreferrDateRef (click)="picker1.open()">
          <input matInput placeholder="Select date" [matDatepicker]="picker1" [min]="minDate"
            formControlName="preffered_date_of_pick_up" required readonly/>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 (closed)="otherPreferrDateClose($event)"></mat-datepicker>
          <mat-error *ngIf="preffered_date_of_pick_up.hasError('required')">Required *</mat-error>
        </mat-form-field>
        <p class="hint__text"
        [ngClass]="{'pd-top': (preffered_date_of_pick_up.touched || preffered_date_of_pick_up.dirty) && preffered_date_of_pick_up.invalid}"
        >
          We might not be able to meet your request delivery date if you live in top-end or bottom-end of Japan.
          We will send you another email to inform you the scheduled delivery date.
        </p>
      </div>
    </div>

    <div class="form__item">
      <label class="label">Estimated time of arrival</label>
      <div class="form__name">
        <mat-form-field appearance="standard">
          <mat-select disableRipple [placeholder]="'ETA to your pick up place'" formControlName="estimated_time_of_arrival">
            <mat-option *ngFor="let time of estimateTimeArrival" [value]="time">{{time}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form__item recipient">
      <label class="label">RECIPIENT<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #otherRecipientRef>
          <input matInput formControlName="recipient" (focus)="otherRecipientChange($event)" required />
          <mat-error *ngIf="recipient.hasError('required')">Required *</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form__item note">
      <label class="label"> note </label>
      <div class="form__name">
        <mat-form-field appearance="standard">
          <textarea matInput placeholder="Please use this box if you have any question regarding your pick up process."
            formControlName="note" maxlength="250"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
