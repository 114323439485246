<div class="payment__information">
  <p class="payment__information__note">note:</p>
  <div class="payment__information__card">
    <div class="card accept__card">
      <p class="card__title">Valid cards:</p>
      <p class="card__info">
        We accept both Debit and Credit cards. Cards issued from your
        country can also be accepted.
      </p>
    </div>
  </div>
  <div class="payment__information__images">
    <img src="../../../assets/images/Asset 1@3x.png" alt="all type credit card accepted" class="images__card" />
  </div>
  <div class="payment__information__not__accept__card">
    <p class="card__title">The following cards will not be accepted:</p>
    <p class="card__info">
      We do not accept cash cards, prepaid debit cards such as Mijica
      Card, Line Pay Card,…
    </p>
  </div>
  <div class="payment__information__registeredcard">
    <h2 class="payment__information__registeredcard__title">registered card information</h2>
    <hr class="hr">
  </div>
  <div class="payment__information__card__form">
    <form class="form" [formGroup]="newCardInformation">
      <div class="left__form">
        <div class="left__form--card-holder-name">
          <label class="label-card">Card Holder’s Name<app-required></app-required></label>
          <p class="label-small">Must match the one registered on the card</p>
          <mat-form-field class="form-field" #cardHolderNameRef>
            <input matInput class="input" formControlName="card_holder_name"
            (focus)="cardHolderNameChange($event)"
            (blur)="makeCapital($event)"
              [value]="cardName" required />
            <mat-error *ngIf="card_holder_name.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="card_holder_name.hasError('pattern')">Alphabets only*</mat-error>
            <mat-hint class="card-hint">Please do not insert any special characters such as “-“,“.”,...</mat-hint>
          </mat-form-field>
        </div>

        <div class="left__form--card-number">
          <label class="label-card">Card Number<app-required></app-required></label>
          <mat-form-field class="form-field" #cardNumberRef>
            <input matInput formControlName="card_number" [value]="cardNumber"
            (focus)="cardNumberChange($event)"
            (keyup)="cardNumberChange($event)" maxlength="16"
              required />
            <mat-error *ngIf="
              card_number.hasError('pattern') &&
              !card_number.hasError('required')
            ">Number only</mat-error>
            <mat-error *ngIf="card_number.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="
            card_number.hasError('minlength') &&
            !card_number.hasError('required') &&
            !card_number.hasError('pattern')
            ">Minimum 15 number</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="right__form">
        <div class="right__form--expire-date">
          <label class="label-card">Expiry Date<app-required></app-required></label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker #expireMonthRef>
            <input matInput placeholder="MM" (focus)="expireMonthChange($event)" formControlName="expire_month" [value]="expireMonth"
              (blur)="onBlurExpireMonth($event)" maxlength="2" required />
            <mat-error *ngIf="
              expire_month.hasError('pattern') &&
              !expire_month.hasError('required')
            ">Number only</mat-error>
            <mat-error *ngIf="expire_month.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="
              expire_month.hasError('minlength') &&
              !expire_month.hasError('required') &&
              !expire_month.hasError('pattern')
            ">Minimum 2 number</mat-error>
            <mat-error *ngIf="newCardInformation.hasError('cardExpired')">Invalid date</mat-error>
          </mat-form-field>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker #expireYearRef>
            <input matInput placeholder="YY" (focus)="expireYearChange($event)" formControlName="expire_year" [value]="expireYear"
              (blur)="onBlurExpireYear($event)" maxlength="2" required />
            <mat-error *ngIf="
              expire_year.hasError('pattern') &&
              !expire_year.hasError('required')
            ">Number only</mat-error>
            <mat-error *ngIf="expire_year.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="
              expire_year.hasError('minlength') &&
              !expire_year.hasError('required') &&
              !expire_year.hasError('pattern')
            ">Minimum 2 number</mat-error>
            <mat-error *ngIf="newCardInformation.hasError('cardExpired')">Invalid date</mat-error>
          </mat-form-field>
        </div>

        <div class="right__form--cvv">
          <label class="label-card cvv-label">CVV<app-required></app-required></label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker #cvvRef>
            <input matInput placeholder="123" (focus)="cvvChange($event)" formControlName="card_cvv" maxlength="4" required />
            <mat-error *ngIf="
              card_cvv.hasError('pattern') && !card_cvv.hasError('required')
            ">Number only</mat-error>
            <mat-error *ngIf="
              card_cvv.hasError('required')
            ">Required*</mat-error>
            <mat-error *ngIf="
            card_cvv.hasError('minlength') &&
            !card_cvv.hasError('required') &&
            !card_cvv.hasError('pattern')
          ">Minimum 3 number</mat-error>
          </mat-form-field>
        </div>

        <div class="right__form--card-type">
          <label class="label-card card__type">Card Type<app-required></app-required></label>
          <div>
            <mat-radio-group #cardTypeRef formControlName="payment_method" (change)="cardTyepChange($event)" required >
              <mat-radio-button value="Debit">Debit</mat-radio-button>
              <mat-radio-button value="Credit">Credit</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mat-raido-error" *ngIf="(payment_method.touched || payment_method.dirty) && payment_method.hasError('required')">Select one</mat-error>
          </div>
        </div>

        <div class="right__form--country">
          <label class="label-card">Card Issuing Country<app-required></app-required></label>
          <mat-form-field class="form-field" #issuingCountryRef>
            <mat-select formControlName="country" required (selectionChange)="issuingCountrySelectChange($event)">
              <mat-option *ngFor="let country of countryListAllIsoData" [value]="country.code3">{{ country.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="country.hasError('required')">Select one</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="message">
      <label class="title">Note:</label>
      <p class="info">
        This newly registered card will be used to make future payments for all contracts that are under this email
        address.
      </p>
    </div>
  </div>
  <hr class="inline__hr">
</div>
