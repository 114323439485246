<div class="hotel">
  <p class="hotel__info">Recipient name needs to match name on your hotel reservation. *Hotels will check recipient name
    with their reservation list.
    Please also include check in/out dates so that hotels can find it easily.</p>

  <div class="hotel__form">
    <form class="hotel__form__data" [formGroup]="hotelInformation">
      <div class="form__item hotel__name">
        <label class="label">HOTEL NAME<app-required></app-required></label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #hotelNameRef>
            <input matInput required formControlName="hotel_name" (focus)="hotelNameChange($event)" required/>
            <mat-error *ngIf=" hotel_name.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form__item hotel__address">
        <label class="label">HOTEL ADDRESS<app-required></app-required></label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #hotelAddressRef>
            <input matInput required formControlName="hotel_address" (focus)="hotelAddressChange($event)" required/>
            <mat-error *ngIf=" hotel_address.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form__item hotel__url__website">
        <label class="label">HOTEL WEBSITE URL</label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field">
            <input matInput formControlName="hotel_url" />
          </mat-form-field>
          <p class="hint__text">
            We will double-check hotel address from their website
          </p>
        </div>
      </div>

      <div class="checkin__checkout">
        <div class="form__item check__in">
          <label class="label">CHECK-IN DATE<app-required></app-required></label>
          <div class="form__name">
            <mat-form-field appearance="standard" class="form--field" #checkInRef (click)="picker1.open()">
              <input matInput placeholder="Select date" [matDatepicker]="picker1" formControlName="hotel_check_in_date"
                required readonly/>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 (closed)="checkInDatePickerCloseEvent(picker1)"></mat-datepicker>
              <mat-error *ngIf="hotel_check_in_date.hasError('required')">Required *</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form__item check__out">
          <label class="label">CHECK-OUT DATE<app-required></app-required></label>
          <div class="form__name">
            <mat-form-field appearance="standard" class="form--field" #checkOutRef (click)="picker2.open()">
              <input matInput placeholder="Select date" [matDatepicker]="picker2" formControlName="hotel_check_out_date"
                required readonly/>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 (closed)="checkOutDatePickerCloseEvent(picker2)"></mat-datepicker>
              <mat-error *ngIf="hotel_check_out_date.hasError('required')">Required *</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form__item">
        <label class="label">preferred date of pick up</label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" (click)="picker3.open()">
            <input matInput placeholder="Select date" [matDatepicker]="picker3" [min]="minDate"
              formControlName="preffered_date_of_pick_up" readonly/>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
          <p class="hint__text">
            If you do not specify a desired delivery date, we will deliver your order as possible.
          </p>
          <p class="hint__text">
            We might not be able to meet your request delivery date if you live in top-end or bottom-end of Japan.
            We will send you another email to inform you the scheduled delivery date.
          </p>
        </div>
      </div>

      <div class="form__item hotel__adress">
        <label class="label">RECIPIENT<app-required></app-required></label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #recipientRef>
            <input matInput formControlName="recipient" (focus)="recipientChange($event)" required />
            <mat-error *ngIf="recipient.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form__item note">
        <label class="label"> note </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <textarea matInput
              placeholder="Please use this box if you have any question regarding your pick up process."
              formControlName="note" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
