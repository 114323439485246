import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { MY_FORMATS } from 'src/app/myFormatDate';
import { LongOrderService } from 'src/app/services/long-order.service';
import { SelectPayMethod } from 'src/app/types/select-paymethod';
import { Add_Remove_Css, ShowSnackBar } from 'src/app/Utils/utils';
import { SubSink } from 'subsink';
import { UploadDocumentComponent } from '../../shared/upload-document/upload-document.component';
import { HotelComponent } from './hotel/hotel.component';
import { OtherComponent } from './other/other.component';
import { PostOfficeComponent } from './post-office/post-office.component';
import { ResidenceComponent } from './residence/residence.component';

@Component({
  selector: 'app-delivery-content',
  templateUrl: './delivery-content.component.html',
  styleUrls: ['./delivery-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryContentComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  private sub = new SubSink();
  listPickupLocation = [
    'Residence / Corporate office / Friends house',
    'Hotel',
    'Post Offices',
    'Other',
  ];
  deliveryTimeSpecify = [
    '8:00 ~ 12:00',
    '12:00 ~ 14:00',
    '14:00 ~ 16:00',
    '16:00 ~ 18:00',
    '18:00 ~ 20:00',
    '18:00 ~ 21:00',
    '19:00 ~ 21:00',
    'Do not specify (can be delivered any time)',
  ];

  isMobileScreen = false;
  @ViewChild('deliveryTimeSpectifyFF', { static: false, read: ElementRef })
  deliveryTimeSpectifyFF: ElementRef;
  // DOM Pickup location button: delivery - data and wifi sim
  @ViewChild('residenceRef', { static: false, read: ElementRef })
  residenceRef: ElementRef;
  @ViewChild('hotelRef', { static: false, read: ElementRef })
  hotelRef: ElementRef;
  @ViewChild('postOfficeRef', { static: false, read: ElementRef })
  postOfficeRef: ElementRef;
  @ViewChild('otherRef', { static: false, read: ElementRef })
  otherRef: ElementRef;

  @ViewChild(UploadDocumentComponent, { static: false })
  uploadComponent: UploadDocumentComponent;
  @ViewChild(HotelComponent, { static: false }) hotelComponent: HotelComponent;
  @ViewChild(ResidenceComponent, { static: false })
  residentComponent: ResidenceComponent;
  @ViewChild(PostOfficeComponent, { static: false })
  postOfficeComponent: PostOfficeComponent;
  @ViewChild(OtherComponent, { static: false }) otherComponent: OtherComponent;
  // case delivery not have voice
  isPickupResidence = false;
  isPickupHotel = false;
  isPickupPostOffice = false;
  isPickupOther = false;
  delivery_information: FormGroup;
  voiceIncludeFormGroup: FormGroup;
  preffered_date_of_pick_up: FormControl;
  delivery_time_specify: FormControl;
  contact_phone_number: FormControl;
  pick_up_location: FormControl;
  note: FormControl;
  isFormValid = false;
  minDate: Date;
  loading$ = this.longorderService.loading$;
  typeContract: string;
  residenceOnly: boolean;
  isPickupLocationSelectOneErr: boolean;
  @Input() dataJson: null | SelectPayMethod;
  @Input() arrivalDateTime: string;
  @Input() subscription_type: string;
  @Input() payment_type: string;
  @Input() pick_up_method: string;
  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private longorderService: LongOrderService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private cdt: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.typeContract === 'home_wifi' && changes.payment_type) {
      if (this.pick_up_location !== undefined) {
        this.pick_up_location.reset();
      }
      if (this.payment_type === 'CREDIT CARD') {
        this.residenceOnly = false;
        this.isPickupResidence = true;
        this.pick_up_location.patchValue(
          'Residence/Cooporate Offices/Friends house'
        );
      }
      if (this.payment_type === 'SMARTPIT') {
        this.residenceOnly = true;
      }
    }
  }
  ngOnInit() {
    console.log(this.subscription_type, this.payment_type, this.pick_up_method);
    // calculate current JST
    const dt_local = new Date();
    const dt_as_jst = new Date();
    // tslint:disable-next-line:max-line-length
    dt_as_jst.setTime(
      dt_local.getTime() +
        dt_local.getTimezoneOffset() * 60 * 1000 +
        9 * 60 * 60 * 1000 /* Offset for Japanese Standard Time */
    );

    if (this.dataJson.pay_method === 'SMARTPIT' && this.dataJson.delivery) {
      this.residenceOnly = true;
    }
    const hours = new Date().getHours();
    if (this.dataJson.dt_offset === 1) {
      this.minDate = new Date(dt_as_jst);
      this.minDate.setDate(this.minDate.getDate() + 2);
    }
    if (this.dataJson.dt_offset === 2 || this.dataJson.dt_offset === 3) {
      this.minDate = new Date(dt_as_jst);
      this.minDate.setDate(this.minDate.getDate() + 2);
    }
    if (this.dataJson.dt_offset === 4) {
      this.minDate = new Date(dt_as_jst);
      if (hours < 12) {
        this.minDate.setDate(this.minDate.getDate() + 1);
      } else {
        this.minDate.setDate(this.minDate.getDate() + 2);
      }
    }
    this.createFormControl();
    this.createFormGroup();
    this.sub.sink = this.longorderService.subcriptionType$.subscribe(
      (value) => (this.typeContract = value)
    );
    if (this.typeContract === 'home_wifi' && (this.payment_type === 'CREDIT CARD')) {
      this.residenceOnly = false;
      this.isPickupResidence = true;
      this.pick_up_location.patchValue(
        'Residence/Cooporate Offices/Friends house'
      );
    }
  }
  ngAfterViewChecked() {
    this.isMobileScreen = this.breakpointObserver.isMatched(
      '(max-width: 480px)'
    );
    this.cdt.detectChanges();
  }

  get HotelInformation() {
    const checkInDate: Moment = this.hotelComponent.hotel_check_in_date.value;
    const checkOutDate: Moment = this.hotelComponent.hotel_check_out_date.value;
    const prefferedDate: Moment = this.hotelComponent.preffered_date_of_pick_up
      .value;
    this.hotelComponent.hotelInformation.patchValue({
      hotel_check_in_date: checkInDate.format('YYYY-MM-DD'),
      hotel_check_out_date: checkOutDate.format('YYYY-MM-DD'),
    });
    if (prefferedDate) {
      this.hotelComponent.hotelInformation.patchValue({
        preffered_date_of_pick_up: prefferedDate.format('YYYY-MM-DD'),
      });
    } else {
      this.hotelComponent.hotelInformation.patchValue({
        preffered_date_of_pick_up: prefferedDate,
      });
    }
    return this.hotelComponent.hotelInformation.getRawValue();
  }
  get ResidentInofrmation() {
    const refferedDate: Moment = this.residentComponent
      .preffered_date_of_pick_up.value;
    if (refferedDate) {
      this.residentComponent.residentInformation.patchValue({
        preffered_date_of_pick_up: refferedDate.format('YYYY-MM-DD'),
      });
    }
    return this.residentComponent.residentInformation.getRawValue();
  }
  get PostOfficeInformation() {
    const prefferedDate: Moment = this.postOfficeComponent
      .preffered_date_of_pick_up.value;
    this.postOfficeComponent.postOfficeFG.patchValue({
      preffered_date_of_pick_up: prefferedDate.format('YYYY-MM-DD'),
    });
    return this.postOfficeComponent.postOfficeFG.getRawValue();
  }
  get OtherInformation() {
    const prefferedDate: Moment = this.otherComponent.preffered_date_of_pick_up
      .value;
    this.otherComponent.otherFG.patchValue({
      preffered_date_of_pick_up: prefferedDate.format('YYYY-MM-DD'),
    });
    return this.otherComponent.otherFG.getRawValue();
  }
  get VoiceIncludeFG() {
    const prefferedDate: Moment = this.preffered_date_of_pick_up.value;
    if (prefferedDate) {
      this.voiceIncludeFormGroup.patchValue({
        preffered_date_of_pick_up: prefferedDate.format('YYYY-MM-DD'),
      });
    }
    console.log(this.voiceIncludeFormGroup.getRawValue());
    return this.voiceIncludeFormGroup.getRawValue();
  }
  hotelFormValidChange(value: boolean) {
    this.isFormValid = value;
  }
  residentFormValidChange(value: boolean) {
    this.isFormValid = value;
  }
  postOfficeFormValidChange(value: boolean) {
    this.isFormValid = value;
  }
  otherFormValidChange(value: boolean) {
    this.isFormValid = value;
  }
  deliveryTimeSpecifySelectEvent(event: any) {
    Add_Remove_Css('remove', this.deliveryTimeSpectifyFF, this.renderer);
  }
  submitDelivery() {
    const key_delivery = () => this.typeContract === 'voice' || this.typeContract === 'voice_esim' || this.typeContract === 'voice_esim' || this.typeContract === 'voice_wifi' || this.residenceOnly
        ? true
        : false;
    switch (key_delivery()) {
      case true:
        // Case: Delivery has voice sim
        // Check all form field invalid
        if (
          this.voiceIncludeFormGroup.invalid ||
          this.uploadComponent.selectDocument.invalid
        ) {
          if (this.voiceIncludeFormGroup.invalid) {
            this.checkDeliveryTimeSpectifyFF();
          }
          if (this.uploadComponent.selectDocument.invalid) {
            this.checkSelectDocument();
          } else {
            this.uploadComponent.checkUploadDocument();
          }
          return;
        } else {
          // Check all form field of upload component
          if (
            (this.uploadComponent.showUploadFront &&
              this.uploadComponent.processUploadFront === false) ||
              (this.uploadComponent.showUploadBack &&
                this.uploadComponent.processUploadBack === false) ||
            ( this.uploadComponent.isAddMoreDocument &&
              this.uploadComponent.processUploadAddMoreDoc === false) ||
            (this.uploadComponent.student_discount_checkbox &&
              this.uploadComponent.processUploadStdID === false)
          ) {
            this.uploadComponent.checkUploadDocument();
            return;
          }
        }
        // All form field of delivery - vocie and upload component has valid
        // if (this.voiceIncludeFormGroup.valid) {
        this.delivery_information.patchValue({
          pick_up_location: 'The address written on the photo ID',
        });
        const formValue = this.delivery_information.getRawValue();
        const voiceIncludeFG = this.VoiceIncludeFG;
        this.longorderService.setDeliveryInformation = {
          ...formValue,
          ...voiceIncludeFG,
        };
        // }
        this.router.navigate(['/step3']);
        break;
      case false:
        // Case: Delivery with data sim or pocket wifi
        // Check all pickup location button and upload component same time
        if ((this.isPickupHotel === false && this.isPickupResidence === false &&
          this.isPickupPostOffice === false && this.isPickupOther === false) ||
          this.uploadComponent.selectDocument.invalid) { // case:: not pick up location and not select upload document
          if (
            this.isPickupHotel === false &&
            this.isPickupResidence === false &&
            this.isPickupPostOffice === false &&
            this.isPickupOther === false
          ) {
            this.isPickupLocationSelectOneErr = true;
            this.checkPickupLocationButton();
          } else {
            this.check_Residence_Hotel_Post_Other();
          }
          if (this.uploadComponent.selectDocument.invalid) {
            this.checkSelectDocument();
          }
          return;
        } else {
          // Case: Pick up location has choosed
          // Check each case: Residence - Hotel - Post Office - Other
          if (this.uploadComponent.selectDocument.valid) {
            this.check_Residence_Hotel_Post_Other();
            // Check upload component
            if (
              (this.uploadComponent.showUploadFront &&
                this.uploadComponent.processUploadFront === false) ||
                (this.uploadComponent.showUploadBack &&
                  this.uploadComponent.processUploadBack === false) ||
              ( this.uploadComponent.isAddMoreDocument &&
                this.uploadComponent.processUploadAddMoreDoc === false) ||
              (this.uploadComponent.student_discount_checkbox &&
                this.uploadComponent.processUploadStdID === false)
            ) {
              this.uploadComponent.checkUploadDocument();
              return;
            }
          }
          // Case pick up residence
          if (
            this.isPickupResidence &&
            this.residentComponent.residentInformation.invalid
          ) {
            this.residenceShowError();
          } else if (this.isPickupResidence) {
            const formValueResidence = this.delivery_information.getRawValue();
            const residentInformation = this.ResidentInofrmation;
            this.longorderService.setDeliveryInformation = {
              ...formValueResidence,
              ...residentInformation,
            };
            this.router.navigate(['/step3']);
          }
          // Case pick up hotel
          if (
            this.isPickupHotel &&
            this.hotelComponent.hotelInformation.invalid
          ) {
            this.hotelShowError();
          } else if (this.isPickupHotel) {
            const formValueHotel = this.delivery_information.getRawValue();
            const hotelInfo = this.HotelInformation;
            this.longorderService.setDeliveryInformation = {
              ...formValueHotel,
              ...hotelInfo,
            };
            this.router.navigate(['/step3']);
          }
          // Case hotel
          if (
            this.isPickupPostOffice &&
            this.postOfficeComponent.postOfficeFG.invalid
          ) {
            this.postOfficeShowError();
          } else if (this.isPickupPostOffice) {
            const formValuePostOffice = this.delivery_information.getRawValue();
            const postOfficeInformation = this.PostOfficeInformation;
            this.longorderService.setDeliveryInformation = {
              ...formValuePostOffice,
              ...postOfficeInformation,
            };
            this.router.navigate(['/step3']);
          }
          // Case Other
          if (this.isPickupOther && this.otherComponent.otherFG.invalid) {
            this.otherShowErr();
          } else if (this.isPickupOther) {
            const formValueOther = this.delivery_information.getRawValue();
            const otherInformation = this.OtherInformation;
            this.longorderService.setDeliveryInformation = {
              ...formValueOther,
              ...otherInformation,
            };
            this.router.navigate(['/step3']);
          }
        }
        break;

      default:
        break;
    }
  }
  pickUpResidence() {
    this.isPickupResidence = !this.isPickupResidence;
    this.isPickupPostOffice = false;
    this.isPickupHotel = false;
    this.isPickupOther = false;
    this.pick_up_location.patchValue(
      'Residence/Cooporate Offices/Friends house'
    );
    this.removeClassNotSelectButton();
  }
  pickUpHotel() {
    this.isPickupHotel = !this.isPickupHotel;
    this.isPickupResidence = false;
    this.isPickupPostOffice = false;
    this.isPickupOther = false;
    this.pick_up_location.patchValue('Hotel');
    this.removeClassNotSelectButton();
  }
  pickUpPostOffice() {
    this.isPickupPostOffice = !this.isPickupPostOffice;
    this.isPickupOther = false;
    this.isPickupResidence = false;
    this.isPickupHotel = false;
    this.pick_up_location.patchValue('Post-offices');
    this.removeClassNotSelectButton();
  }
  pickUpOther() {
    this.isPickupOther = !this.isPickupOther;
    this.isPickupPostOffice = false;
    this.isPickupResidence = false;
    this.isPickupHotel = false;
    this.pick_up_location.patchValue('Other');
    this.removeClassNotSelectButton();
  }
  private check_Residence_Hotel_Post_Other() {
    if (
      this.isPickupResidence &&
      this.residentComponent.residentInformation.invalid
    ) {
      console.log('======>  Residence check all form field inside');
      this.residenceShowError();
    }
    if (
      this.isPickupHotel &&
      this.hotelComponent.hotelInformation.invalid
    ) {
      console.log('======>  Hotel check all form field inside');
      this.hotelShowError();
    }
    if (
      this.isPickupPostOffice &&
      this.postOfficeComponent.postOfficeFG.invalid
    ) {
      console.log('======>  PostOffice check all form field inside');
      this.postOfficeShowError();
    }
    if (this.isPickupOther && this.otherComponent.otherFG.invalid) {
      console.log('======>  Other check all form field inside');
      this.otherShowErr();
    }
  }
  private checkSelectDocument() {
    if (this.uploadComponent.selectDocument.invalid) {
      this.uploadComponent.selectDocument.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.uploadComponent.selectDocumentFF,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
  }
  private residenceShowError() {
    if (this.residentComponent.recipient.invalid) {
      this.residentComponent.recipient.markAllAsTouched();
      Add_Remove_Css('add', this.residentComponent.recipientRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.residentComponent.residential_address.invalid) {
      this.residentComponent.residential_address.markAllAsTouched();
      Add_Remove_Css('add', this.residentComponent.addressRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }
  private hotelShowError() {
    if (this.hotelComponent.hotel_name.invalid) {
      this.hotelComponent.hotel_name.markAllAsTouched();
      Add_Remove_Css('add', this.hotelComponent.hotelNameRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.hotelComponent.hotel_address.invalid) {
      this.hotelComponent.hotel_address.markAllAsTouched();
      Add_Remove_Css('add', this.hotelComponent.hotelAddressRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.hotelComponent.hotel_check_in_date.invalid) {
      this.hotelComponent.hotel_check_in_date.markAllAsTouched();
      Add_Remove_Css('add', this.hotelComponent.checkInRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.hotelComponent.hotel_check_out_date) {
      this.hotelComponent.hotel_check_out_date.markAllAsTouched();
      Add_Remove_Css('add', this.hotelComponent.checkOutRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.hotelComponent.recipient.invalid) {
      this.hotelComponent.recipient.markAllAsTouched();
      Add_Remove_Css('add', this.hotelComponent.recipientRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }
  private postOfficeShowError() {
    if (this.postOfficeComponent.post_office_branch_name.invalid) {
      this.postOfficeComponent.post_office_branch_name.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.postOfficeComponent.postOfficeBranchNameRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
    if (this.postOfficeComponent.post_office_branch_address.invalid) {
      this.postOfficeComponent.post_office_branch_address.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.postOfficeComponent.postOfficeAddressRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
    if (this.postOfficeComponent.preffered_date_of_pick_up.invalid) {
      this.postOfficeComponent.preffered_date_of_pick_up.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.postOfficeComponent.postOfficePreferrDateRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
    if (this.postOfficeComponent.recipient.invalid) {
      this.postOfficeComponent.recipient.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.postOfficeComponent.postOfficeRecipientRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
  }
  private otherShowErr() {
    if (this.otherComponent.shipping_address.invalid) {
      this.otherComponent.shipping_address.markAllAsTouched();
      Add_Remove_Css('add', this.otherComponent.otherAddressRef, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.otherComponent.preffered_date_of_pick_up.invalid) {
      this.otherComponent.preffered_date_of_pick_up.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.otherComponent.otherPreferrDateRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
    if (this.otherComponent.recipient.invalid) {
      this.otherComponent.recipient.markAllAsTouched();
      Add_Remove_Css(
        'add',
        this.otherComponent.otherRecipientRef,
        this.renderer
      );
      ShowSnackBar(this.snackbar);
    }
  }
  private checkDeliveryTimeSpectifyFF() {
    if (this.delivery_time_specify.invalid) {
      this.delivery_time_specify.markAllAsTouched();
      Add_Remove_Css('add', this.deliveryTimeSpectifyFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }
  private checkPickupLocationButton() {
    if (!this.isPickupResidence) {
      this.renderer.addClass(
        this.residenceRef.nativeElement,
        'not-select-button'
      );
    }
    if (!this.isPickupHotel) {
      this.renderer.addClass(this.hotelRef.nativeElement, 'not-select-button');
    }
    if (!this.isPickupPostOffice) {
      this.renderer.addClass(
        this.postOfficeRef.nativeElement,
        'not-select-button'
      );
    }
    if (!this.isPickupOther) {
      this.renderer.addClass(this.otherRef.nativeElement, 'not-select-button');
    }
  }
  private removeClassNotSelectButton() {
    this.renderer.removeClass(
      this.residenceRef.nativeElement,
      'not-select-button'
    );
    this.renderer.removeClass(this.hotelRef.nativeElement, 'not-select-button');
    this.renderer.removeClass(
      this.postOfficeRef.nativeElement,
      'not-select-button'
    );
    this.renderer.removeClass(this.otherRef.nativeElement, 'not-select-button');
    this.isPickupLocationSelectOneErr = false;
  }
  private createFormControl() {
    this.pick_up_location = this.fb.control('', Validators.required);
    this.preffered_date_of_pick_up = this.fb.control('');
    this.delivery_time_specify = this.fb.control('', Validators.required);
    this.contact_phone_number = this.fb.control('');
    this.note = this.fb.control('');
  }
  private createFormGroup() {
    this.delivery_information = this.fb.group({
      pick_up_location: this.pick_up_location,
    });
    this.voiceIncludeFormGroup = this.fb.group({
      preffered_date_of_pick_up: this.preffered_date_of_pick_up,
      delivery_time_specify: this.delivery_time_specify,
      contact_phone_number: this.contact_phone_number,
      note: this.note,
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
