import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';
import { set_Background_Snackbar_NewCard } from '../Utils/utils';

export class CustomValicators {
  public static kanaTextValicator(error: ValidationErrors): ValidatorFn {
    const typeKanaText: RegExp = /^[\u3000\u0020\u30a0-\u30ff\uff61-\uff9fｦ-ﾟ]*$/;
    return (control: AbstractControl): { [key: string]: any} => {
      if (!control.value) {
        return null;
      }
      const valid = typeKanaText.test(control.value);
      return valid ? null : error;
    };
  }
  public static checkCardExpired(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const expire_month = form.get('expire_month').value;
      const expire_year = form.get('expire_year').value;
      if (!expire_month || !expire_year) {
        return null;
      }
      const date = new Date();
      const current_month = date.getMonth();
      const current_year = date.getFullYear();
      const yy_pre_digit = current_year.toString().substring(0, 2);
      const month_year = new Date(current_year, current_month);
      if (expire_year !== null && expire_month !== null) {
        const number_to_string = yy_pre_digit + expire_year;
        const expire_month_year_of_card = new Date(
          Number(number_to_string),
          expire_month - 1
        );
        const isCardExpire = expire_month_year_of_card.getTime() - month_year.getTime() >= 0;
        if (!isCardExpire) {
          form.get('expire_month').setErrors({ monthExpired: true });
          form.get('expire_year').setErrors({ yearExpired: true });
          return { cardExpired: true };
        } else {
          form.get('expire_month').setErrors(null);
          form.get('expire_year').setErrors(null);
          return null;
        }
      }
    };
  }
}
