import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "src/app/constants/constants";
import { LongOrderService } from "src/app/services/long-order.service";
import { SelectPayMethod } from "src/app/types/select-paymethod";
import { SubSink } from "subsink";
import { paymentMethodSelect } from "../../../payment-method-select";
@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  private subsink = new SubSink();
  isCreditCard = false;
  isCashPayment = false;
  isAirportAll = false;
  isOffice = false;
  isDelivery = false;
  disableCreditCard = false;
  disableCashPayment = false;
  isSorryScreen = false;
  contractHash: string;
  hasVoice: boolean;
  isResponsiveFinish: boolean;
  arrivalDateTime: string;
  creditCardAvailble: boolean;
  subscription_type: string;
  payment_type: string;
  pick_up_method: string;
  typeContract: string;
  ekyc_status: string;
  ekyc_uid: string;
  ekyc_url: string;
  quantity: string;
  document_other_note: string;
  public subcriptionType: string;
  private currentLocation: string;
  private numberDay: number;
  public dataJson: null | SelectPayMethod;
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private longorderService: LongOrderService
  ) {
  }

  public get use_ekyc(): boolean {
    return this.ekyc_status && this.ekyc_status.length > 0 && this.ekyc_status !== 'None(Document File)';
  }

  public get start_ekyc(): boolean {
    return this.ekyc_status === 'START';
  }

  public get complete_ekyc(): boolean {
    return this.ekyc_status === 'COMPLETE';
  }

  public get is_esim_v(): boolean {
    if (!this.subscription_type) {
      return false;
    }
    if (this.subscription_type.toUpperCase().indexOf('VOICE + DATA ESIM') < 0) {
      return false;
    }
    return true;
  }

  public get is_esim_d(): boolean {
    if (!this.subscription_type) {
      return false;
    }
    if (this.subscription_type.toUpperCase().indexOf('DATA ONLY ESIM')) {
      return false;
    }
    return true;
  }

  public get is_esim_d_credit(): boolean {
    return this.is_esim_d && this.isCreditCard && !this.complete_ekyc;
  }

  ngOnInit() {
    this.isResponsiveFinish = true;
    this.dataJson = {
      order_from: 'IN JAPAN',
      pay_method: 'CREDIT CARD',
      dt_offset: 4,
      has_voice: true,
      airport: false,
      airport_candidates: { NRT_KIX: false, HND: false, other: false },
      office: true,
      office_candidates: { shinjuku: true, other: true },
      delivery: true,
    };
    this.subsink.sink = this.activeRoute.queryParams.subscribe((res) => {
      this.contractHash = res.order;
      localStorage.setItem("hash_string", this.contractHash);
      // console.log(this.contractHash);
      this.longorderService.setContractHash = res.order;
    });
    this.subsink.sink = this.longorderService.subcriptionType$.subscribe(
      (value) => (this.typeContract = value)
    );

    this.subsink.sink = this.longorderService
      .getOrderInformation(this.contractHash)
      .subscribe((data) => {
        this.isResponsiveFinish = false;
        const [orderInformation, activePayment] = data;
        console.log("Order informations::: ", orderInformation);
        // Store order information
        if (orderInformation.data.contract_status ===
          430 /* 'V2 DEMAND EKYC' (the customer must try ekyc again) */) {
          if (orderInformation.data.customer.ekyc_status) {
            this.ekyc_status = orderInformation.data.customer.ekyc_status;
            this.ekyc_uid = orderInformation.data.customer.ekyc_uid;
            this.ekyc_url = orderInformation.data.customer.ekyc_url;
            if (this.ekyc_status === 'START') {
              window.location.href = this.ekyc_url;
              return;
            }
          }
        }
        else if (
          orderInformation.data.contract_status ===
          100 /* 'ACCESS ONLY' (the customer finished step 1) */ ||
          orderInformation.data.contract_status ===
          410 /* 'V2 DEMAND STEP 2' (the customer got demand notification email) */
        ) {
          this.quantity = orderInformation.data.quantity;
          this.subscription_type = orderInformation.data.subscription_plan;
          console.log("Subscription type::", this.subscription_type);
          // Check credit card availablity
          const email = orderInformation.data.customer.email;
          if ((this.is_esim_d || this.is_esim_v) && orderInformation.data.customer.ekyc_status) {
            this.ekyc_status = orderInformation.data.customer.ekyc_status;
            this.ekyc_uid = orderInformation.data.customer.ekyc_uid;
            this.ekyc_url = orderInformation.data.customer.ekyc_url;
            if (this.ekyc_status === 'START') {
              window.location.href = this.ekyc_url;
              return;
            }
          }
          this.subsink.sink = this.longorderService
            .creditCardAvailablity(email)
            .subscribe((res) => {
              console.log("Credit card availability", res);
              this.creditCardAvailble = !res;
            });
          // Check coupon code avaible
          if (orderInformation.data.coupon_code) {
            this.snackBar.open(
              "Coupon code is applied.",
              "OK",
              Constants.defaultSnackBarConfig("success")
            );
            this.longorderService.setCouponCode =
              orderInformation.data.coupon_code;
          }
          // Check referral code available
          if (orderInformation.data.referral_code) {
            this.snackBar.open(
              "Referral code is applied.",
              "OK",
              Constants.defaultSnackBarConfig("success")
            );
            this.longorderService.setReferralCode =
              orderInformation.data.referral_code;
          }
          this.longorderService.setOrderInformation = orderInformation;
          // get payment method :: active payment method api
          this.longorderService.setActivePaymentMethod =
            activePayment.data.payment_method;
          if (activePayment.data.payment_method === "SMARTPIT") {
            this.longorderService.setActiveMethodInformation =
              activePayment.data.smartpit_info;
          }
          if (activePayment.data.payment_method === "CREDIT CARD") {
            this.longorderService.setActiveMethodInformation =
              activePayment.data.credit_card_info;
          }
          // Store subcription type
          this.longorderService.setSubcriptiontype =
            orderInformation.data.subscription_type;
          // Store subscription plan
          this.subcriptionType = orderInformation.data.subscription_type;
          // Check subscription has voice
          this.hasVoice = this.subcriptionType.includes("voice");
          // console.log('check has vocie ? ---', this.hasVoice);
          this.currentLocation =
            orderInformation.data.location.current_location;
          console.log("Location of japan ---", this.currentLocation);
          this.arrivalDateTime =
            orderInformation.data.location.expected_date_of_arrival;
          // this.numberDatesBefore = this.findDateBefore(this.arrivalDateTime);
          console.log("Expect day arrival:: --------- ", this.arrivalDateTime);
          this.numberDay = this.findIndexPattern(
            this.arrivalDateTime,
            this.currentLocation
          );
          console.log("Index pattern number: --------- ", this.numberDay);
          // Rehandle Credit Card and Cash Payment
          console.log(
            "Active payment method : ",
            activePayment.data.payment_method
          );
          if (activePayment.data.payment_method === "CREDIT CARD") {
            this.disableCashPayment = true;
          }
          if (activePayment.data.payment_method === "SMARTPIT") {
            this.disableCreditCard = true;
          }

          if (orderInformation.data.customer.ekyc_status && orderInformation.data.customer.ekyc_status === 'COMPLETE') {
            // force credit card
            activePayment.data.payment_method = 'CREDIT CARD';
            this.longorderService.setPaymentMethod = 'CREDIT CARD';
            this.payment_type = 'CREDIT CARD';
            this.isCreditCard = true;
            this.isCashPayment = false;
            this.disableCreditCard = false;
            this.disableCashPayment = true;

            // force ekyc
            this.isOffice = false;
            this.isAirportAll = false;
            this.isDelivery = false;
            this.longorderService.setIsDelivery = false;
            this.longorderService.setPickupMethod = "EKYC";
            this.pick_up_method = "EKYC";
          }
        } else {
          this.router.navigate(["completed"]);
        }
      });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  creditCardEvent() {
    this.hidePickupLocation(); // reset payment method
    this.isCreditCard = !this.isCreditCard;
    this.isCashPayment = false;
    this.isSorryScreen = false;
    this.payment_type = "CREDIT CARD";
    this.longorderService.setPaymentMethod = "CREDIT CARD";
    // Rehandle Case OUT SIDE OF JAPAN
    if (this.isCreditCard && this.currentLocation === "OUTSIDE OF JAPAN") {
      this.dataJson = this.findJsonData("CREDIT CARD");
      // console.log("data json ----- OUT JAPAN-CREDIT: ", this.dataJson);
    }
    // IN JAPAN CASE
    if (this.isCreditCard && this.currentLocation === "IN JAPAN") {
      this.dataJson = this.findJsonData("CREDIT CARD");
      // console.log("data json ----- IN JAPAN-CREDIT :", this.dataJson);
    }
    if (this.is_esim_d_credit) {
      this.showDocumentUploadOnlyCom();

    }
    else {
      // End Rehandle
      if (this.typeContract === 'home_wifi' && this.isCreditCard === true) {
        this.showDeliveryCom();
      }
    }
  }
  
  CashPaymentContent() {
    this.hidePickupLocation();
    this.isCreditCard = false;
    this.isCashPayment = !this.isCashPayment;
    this.payment_type = "SMARTPIT";
    this.longorderService.setPaymentMethod = "SMARTPIT";
    // Rehandle Case OUT SIDE OF JAPAN
    if (this.currentLocation === "OUTSIDE OF JAPAN") {
      this.dataJson = this.findJsonData("SMARTPIT");
      if (
        !this.dataJson.airport &&
        !this.dataJson.office &&
        !this.dataJson.delivery
      ) {
        this.isSorryScreen = true;
        this.isCashPayment = false;
      }
      // console.log("data json ----- OUT JAPAN - SMARTPIT:", this.dataJson);
    }
    // IN JAPAN CASE
    if (this.currentLocation === "IN JAPAN") {
      this.dataJson = this.findJsonData("SMARTPIT");
      // console.log("data json ----- IN JAPAN - SMARTPIT:", this.dataJson);
    }
    // End rehandle
    if (this.typeContract === 'home_wifi' && this.isCashPayment === true) {
      this.showDeliveryCom();
    }
  }

  showAirportCom() {
    this.isDelivery = false;
    this.isOffice = false;
    this.isAirportAll = !this.isAirportAll;
    this.longorderService.setIsDelivery = this.isDelivery;
    this.pick_up_method = "AIRPORT";
    this.longorderService.setPickupMethod = "AIRPORT";
  }

  showOfficeCom() {
    this.isDelivery = false;
    this.isAirportAll = false;
    this.isOffice = !this.isOffice;
    this.longorderService.setIsDelivery = this.isDelivery;
    this.pick_up_method = "OFFICE";
    this.longorderService.setPickupMethod = "OFFICE";
  }

  showDeliveryCom() {
    this.isOffice = false;
    this.isAirportAll = false;
    this.isDelivery = !this.isDelivery;
    this.longorderService.setIsDelivery = this.isDelivery;
    this.pick_up_method = "DELIVERY";
    this.longorderService.setPickupMethod = "DELIVERY";
  }

  showDocumentUploadOnlyCom() {
    this.isOffice = false;
    this.isAirportAll = false;
    this.isDelivery = false;
    this.longorderService.setIsDelivery = this.isDelivery;
    this.pick_up_method = "EKYC";
    this.longorderService.setPickupMethod = "EKYC";
  }

  private findIndexPattern(date: string, currentLocation: string): number {
    const currentDate = new Date(); // TODO : convert to JST
    const currentDate_jst = new Date();
    currentDate_jst.setTime(
      currentDate.getTime() +
      currentDate.getTimezoneOffset() * 60 * 1000 +
      9 * 60 * 60 * 1000 /* Offset for Japanese Standard Time */
    );
    const currentHours = currentDate_jst.getHours();
    const dayString = this.formatDate(currentDate_jst);
    const cdt = new Date(dayString).valueOf();
    const edoa = new Date(date).valueOf();
    const oneDateBefore = edoa - 1000 * 60 * 60 * 24;
    const twoDateBefore = edoa - 1000 * 60 * 60 * 24 * 2;
    const threeDateBefore = edoa - 1000 * 60 * 60 * 24 * 3;
    console.log("JST=", currentDate_jst);
    if (currentLocation === "IN JAPAN") {
      return 4;
    } else {
      // At 16:00 the day before EDOA <= CDT < At 00:00 EDOA (end of the day)
      if (
        (cdt === oneDateBefore && currentHours >= 16 && currentHours < 24) ||
        (cdt >= edoa && currentHours >= 0 && currentHours < 24)
      ) {
        return 1;
      }
      // 2 days before EDOA (beginning of the day) < CDT < At 16:00 the day before EDOA (beginning of the day)
      if (
        (cdt === oneDateBefore && currentHours < 16) ||
        (cdt === twoDateBefore && currentHours >= 12)
      ) {
        return 2;
      }
      // CDT < 2 days before EDOA (beginning of the day)
      if (
        (cdt === twoDateBefore && currentHours < 12) ||
        (cdt <= threeDateBefore)
      ) {
        return 3;
      }
    }
  }
  // Format type Date to 'YYYY-MM-DD'
  private formatDate(value: Date) {
    const year = value.getFullYear();
    let month = "" + (value.getMonth() + 1);
    let day = "" + value.getDate();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }
  private findJsonData(paymethod: string): SelectPayMethod {
    const jsondata = paymentMethodSelect.find(
      (data) =>
        data.dt_offset === this.numberDay &&
        data.pay_method === paymethod &&
        data.has_voice === this.hasVoice
    );
    return jsondata;
  }
  // Hide all airport, office, delivery component
  private hidePickupLocation() {
    this.isAirportAll = false;
    this.isOffice = false;
    this.isDelivery = false;
  }
}
