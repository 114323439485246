<div class="upload">
  <h1 class="upload__header">submit required document</h1>
  <!-- <div class="if_cant_provide_document">
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSd4PhC1JRJlpl-TMBHzqD8gKvDj8ma47GJltup4iO9HcrC2Gw/viewform?usp=pp_url&entry.1279877952={{orderInformation.data.contract_code}}" target="_blank">Click here</a> if you cannot provide the required document<br/>
    A free Temporary SIM can be provided for until your documentation are ready.
  </div> -->
  <div class="upload__content">
    <div class="upload__content__documents">
      <div class="select__documents">
        <div *ngIf="!for_v_esim" class="select__document__container">
          <div class="select__type">
            <p class="select__title">
              select document type <app-required></app-required>
            </p>
            <mat-form-field appearance="standard" #selectDocumentFF>
              <mat-select
                disableRipple
                [placeholder]="'Select document type'"
                [formControl]="selectDocument"
                (selectionChange)="selectionChange($event.value)"
              >
                <mat-option
                  class="mat--option"
                  *ngFor="let item of documentTypes"
                  [value]="item"
                  >{{ item }}</mat-option
                >
              </mat-select>
              <mat-error
                class="mat-error-upload"
                *ngIf="selectDocument.hasError('required')"
                >Select one</mat-error
              >
            </mat-form-field>
          </div>
          <div class="document__description">
            <!-- Passport pattern 1 --->
            <ng-container *ngIf="passport_pattern1">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des">
                <img
                  class="image"
                  src="../../../../assets/images/step2_passport.png"
                  alt="passport"
                />
                <div class="desc">Passport ID page</div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The passport and the utility bill must be under the same
                    name.
                  </li>
                  <li>
                    The utility bill must be issued within the last 3 months.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Passport pattern 1 --->

            <!-- Passport pattern 2 --->
            <ng-container *ngIf="passport_pattern2">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--parten2">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/step2_passport.png"
                    alt="passport"
                  />
                  <div class="desc">Passport ID page</div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/utility_bill.png"
                    alt="passport"
                  />
                  <div class="desc">Utility bill</div>
                  <div class="desc">(Gas, water, electricity)</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The passport and the utility bill must be under the same
                    name.
                  </li>
                  <li>
                    The utility bill must be issued within the last 3 months.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Passport pattern 2 --->

            <!-- Residence card--->
            <ng-container *ngIf="residence_card">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/residence_01.jpg"
                    alt="residence card 1"
                  />
                  <div class="desc">{{ titleUploadFront }}</div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/residence_02.jpg"
                    alt="residence card 2"
                  />
                  <div class="desc">{{ titleUploadBack }}</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The residence card must be valid and must have your most
                    recent address written on the back side. You need to go to
                    the local City Hall to register your address.
                  </li>
                  <li>
                    You cannot write the address yourself.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Residence card--->

            <!-- Driver license card--->
            <ng-container *ngIf="driver_license">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/jp_driving_license_01.png"
                    alt="driver license 1"
                  />
                  <div class="desc">{{ titleUploadFront }}</div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/jp_driving_license_02.png"
                    alt="driver license 1"
                  />
                  <div class="desc">{{ titleUploadBack }}</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The card must be valid and must have your most recent
                    address written on.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Driver license card--->

            <!-- Special permanent residence card--->
            <ng-container *ngIf="special_pernament">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/permanent_residence_01.jpeg"
                    alt="permanent residence 1"
                  />
                  <div class="desc">{{ titleUploadFront }}</div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/permanent_residence_02.jpeg"
                    alt="permanent residence 1"
                  />
                  <div class="desc">{{ titleUploadBack }}</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The card must be valid and must have your most recent
                    address written on the back side.<br />
                    You need to go to the local City Hall to register your
                    address.
                  </li>
                  <li>
                    You cannot write the address yourself.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Special permanent residence card--->

            <!-- Driving history certificate--->
            <ng-container *ngIf="driving_history_certificate">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/jp_driving_history_01.jpeg"
                    alt="driving history 1"
                  />
                  <div class="desc">{{ titleUploadFront }}</div>
                </div>
                <div class="image-right">
                  <img
                    class="image driver--history"
                    src="../../../../assets/images/jp_driving_history_02.png"
                    alt="driving history 2"
                  />
                  <div class="desc">{{ titleUploadBack }}</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The card must be valid and must have your most recent
                    address written on.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Driving history certificate--->
            <!-- Personal number card--->
            <ng-container *ngIf="personal_number_card">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/mynumber_01.jpeg"
                    alt="personal number card 1"
                  />
                  <div class="desc">{{ titleUploadFront }}</div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/mynumber_02.png"
                    alt="personal number card 2"
                  />
                  <div class="desc">{{ titleUploadBack }}</div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The card must be valid and must have your most recent
                    address written on.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-- Personal number card--->

            <!-------Insurance card-->
            <ng-container *ngIf="insurance_card">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image--des--insurance-flex">
                  <img
                    class="image"
                    src="../../../../assets/images/insurance_03.png"
                    alt="insurance card sample"
                    style="width: 100%;"
                  />
                  <div class="desc">
                    {{ titleUploadFront }}
                  </div>
                  <div class="desc">
                    {{ titleUploadBack }}
                  </div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The card must be valid and must have your most recent
                    address written on.
                  </li>
                  <li>
                    You
                    <strong
                      >must cover the numbers parts (記号、番号、枝番、保険者番号) as
                      well as the QR/bar code.</strong
                    >
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!-------Insurance card-->

            <!------- Indentification Card-->
            <ng-container *ngIf="!for_v_esim && indentification_card">
              <h3 class="requirement">Requirement:</h3>
              <div class="image--des--residence">
                <div class="image-left">
                  <img
                    class="image"
                    src="../../../../assets/images/id_card_01.svg"
                    alt="indentification card 1"
                  />
                  <div class="desc identification">
                    Identification card (by the Ministry of Foreign Affairs of
                    Japan)
                  </div>
                </div>
                <div class="image-right">
                  <img
                    class="image"
                    src="../../../../assets/images/id_card_02.png"
                    alt="indentification card 2"
                  />
                  <div class="desc">
                    Certificate of Residential Address (by the Ministry of
                    Foreign Affairs of Japan)
                  </div>
                </div>
              </div>
              <div class="conditions--patern2">
                <h3 class="conditions">Conditions:</h3>
                <ul class="des-normal">
                  <li>
                    The identification card and the certificate must be under
                    the same name.
                  </li>
                  <li>
                    The document can not be scanned document. You will have to take a photo of it.
                  </li>
                  <li>
                    Please do not trim the document, just upload as the photo is.
                  </li>
                  <li>
                    Make sure the document is clear, no light or blurred. 
                  </li>
                </ul>
              </div>
            </ng-container>
            <!------- Indentification Card-->
          </div>
        </div>

        <ng-container *ngIf="!for_v_esim && showUploadFront">
          <!-- **FRONT** -->
          <div class="upload__file">
            <p class="select__title">
              {{ titleUploadFront }}<app-required></app-required>
            </p>
            <div class="upload__file__form">
              <div class="upload__file__input" #uploadFrontFF>
                <label for="front" class="label__upload">{{
                  fileNameUploadFront
                    ? fileNameUploadFront
                    : "Choose a file to upload (pdf, jpg, png,…)"
                }}</label>
                <input
                  class="upload"
                  id="front"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (click)="checkUploadFront($event)"
                  (change)="onChangeUploadFront($event)"
                  #front
                />
                <mat-icon
                  matSuffix
                  class="folder-icon"
                  for="front"
                  (click)="front.click()"
                  >folder
                </mat-icon>
              </div>
              <div>
                <p class="mat-error-upload--form" *ngIf="requiredUploadFront">
                  Required*
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- **BACK** -->
        <ng-container *ngIf="!for_v_esim && showUploadBack">
          <div class="upload__file">
            <p class="select__title">
              {{ titleUploadBack }} <app-required></app-required>
            </p>
            <div class="upload__file__form">
              <div class="upload__file__input" #uploadBackFF>
                <label for="back" class="label__upload">{{
                  fileNameUploadBack
                    ? fileNameUploadBack
                    : "Choose a file to upload (pdf, jpg, png,…)"
                }}</label>
                <input
                  class="upload"
                  id="back"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (click)="checkUploadBack($event)"
                  (change)="onChangeUploadBack($event)"
                  #back
                />
                <mat-icon
                  matSuffix
                  class="folder-icon"
                  for="back"
                  (click)="back.click()"
                  >folder
                </mat-icon>
              </div>
              <div>
                <p class="mat-error-upload--form" *ngIf="requiredUploadBack">
                  Required*
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="!for_v_esim" class="add__more__document">
          <button
            class="add__more__document__button"
            [ngClass]="{ 'add-more-documents': enableAddMoreDocument }"
            (click)="addMoreFile()"
          >
            if necessary, click here to add more document
          </button>
          <div class="upload__file" *ngIf="isAddMoreDocument">
            <p class="select__title">File 1 <app-required></app-required></p>
            <div class="upload__file__form">
              <div class="upload__file__input" #uploadAdditionalFF>
                <label for="addMoreFile" class="label__upload">{{
                  fileNameUploadFile1
                    ? fileNameUploadFile1
                    : "Choose a file to upload (pdf, jpg, png,…)"
                }}</label>
                <input
                  class="upload"
                  id="addMoreFile"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (change)="onChangeAddMoreDocument($event)"
                  #addMoreFile
                />
                <mat-icon
                  matSuffix
                  class="folder-icon"
                  for="addMoreFile1"
                  (click)="addMoreFile.click()"
                  >folder
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
        <mat-checkbox class="checkbox" (change)="isStudentCheck($event.checked)"
          >Apply for the student discount (¥1,000 off from the activation
          fee)</mat-checkbox
        >
        <div class="upload--student-id" *ngIf="student_discount_checkbox">
          <div class="upload__file">
            <p class="select__title">
              upload student id <app-required></app-required>
            </p>
            <div class="upload__file__form">
              <div class="upload__file__input" #uploadStdFF>
                <label for="studentId" class="label__upload">{{
                  fileNameUploadSTID
                    ? fileNameUploadSTID
                    : "Choose a file to upload (pdf, jpg, png,…)"
                }}</label>
                <input
                  class="upload"
                  id="studentId"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (click)="checkUploadStd($event)"
                  (change)="onChangeUploadStudentID($event)"
                  #studentId
                />
                <mat-icon
                  matSuffix
                  class="folder-icon"
                  for="studentId"
                  (click)="studentId.click()"
                  >folder
                </mat-icon>
              </div>
              <div>
                <p class="mat-error-upload--form" *ngIf="requiredUploadStd">
                  Required*
                </p>
              </div>
            </div>
          </div>
          <div class="upload__student--message">
            <p class="message--title">
              If you do not have a Student ID yet, please provide us with proof
              of current or future school enrollment.
            </p>
            <p class="message--title">
              Examples of valid documentation include:
            </p>
            <ul>
              <li>
                A screenshot of your emails with the school you will be
                attending with the enrollment and program dates shown
              </li>
              <li>
                Your most recent transcript from your school with the graduation
                date shown
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="loading$ | async">
      <div class="overlay--spinner">
        <app-upload-spinner></app-upload-spinner>
        <span class="overlay--text">Uploading...</span>
      </div>
    </div>
  </div>
</div>
