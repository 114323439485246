<div class="airport__container">
  <ng-container
    *ngIf="dataJson.airport_candidates.NRT_KIX && !dataJson.airport_candidates.HND && !dataJson.airport_candidates.other">
    <h4 class="airport__container__notes">Notes:</h4>
    <p class="airport__container__information">
      ※ Airport pick up option is only available at Narita and Kansai Airport at the moment as shipping to Haneda Airport takes up to 3 days.
    </p>
    <p class="airport__container__information">
      ※ If you are arriving at a different airport, please choose office pick up or delivery option instead.
    </p>
  </ng-container>
  <ng-container
    *ngIf="dataJson.airport_candidates.NRT_KIX && dataJson.airport_candidates.HND && !dataJson.airport_candidates.other">
    <h4 class="airport__container__notes">Notes:</h4>
    <p class="airport__container__information">
      ※ Airport pick up option is only available at Haneda, Narita, and Kansai International Airport.
    </p>
    <p class="airport__container__information">
      ※ If you are arriving at a different airport, please complete the application after arriving in Japan and having
      your address registered on
      your residence card. It is possible to use the same link.
    </p>
  </ng-container>
  <div class="airport__container__form">
    <form class="form__content" [formGroup]="airport_information">
      <div class="form--item pickup__location">
        <label class="label">airport pick up location
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" #airportPickupLocationFF>
            <mat-select disableRipple [placeholder]="placeholder" [formControl]="location_airport"
              (selectionChange)="selectAirportChange($event.value)" required>
              <mat-option *ngFor="let airport of airportList"
              [disabled]="dataJson.airport_candidates.NRT_KIX && dataJson.airport_candidates.HND && dataJson.airport_candidates.other ?
                airport !== 'Haneda International Airport' &&
                airport !== 'Narita International Airport 1' &&
                airport !== 'Narita International Airport 2' &&
                airport !== 'Kansai International Airport' &&
                airport !== 'Other: Please write your arriving airport'
              : dataJson.airport_candidates.NRT_KIX && dataJson.airport_candidates.HND ?
                airport !== 'Haneda International Airport' &&
                airport !== 'Narita International Airport 1' &&
                airport !== 'Narita International Airport 2' &&
                airport !== 'Kansai International Airport'
              : dataJson.airport_candidates.NRT_KIX ?
                airport !== 'Narita International Airport 1' &&
                airport !== 'Narita International Airport 2' &&
                airport !== 'Kansai International Airport' : false" [value]="airport">{{airport}}</mat-option>
            </mat-select>
            <mat-error *ngIf="location_airport.hasError('required')">Select one</mat-error>
          </mat-form-field>
        </div>
        <div class="form__name other__form" *ngIf="otherAirportSelect">
          <mat-form-field appearance="standard">
            <input matInput placeholder="Please write the name of the airport" [formControl]="other_airport" required />
            <mat-error *ngIf="other_airport.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form--item arriving__flight">
        <label class="label">
          Arriving flight number
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" #arrivingFlightNumberFF>
            <input matInput placeholder="Eg: JL891"
            (focus)="arrivingFlightChange($event)"
             formControlName="arriving_flight_number" required />
            <mat-error *ngIf="arriving_flight_number.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form--item pickup__date">
        <label class="label">
          pick up date (Japan entry date)
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #pickupDateFF (click)="picker.open()">
            <input matInput placeholder="Select date"
            formControlName="pick_up_date" [matDatepicker]="picker"
              [min]="selectDate" required readonly/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="checkDatePickerEvent(picker)"></mat-datepicker>
            <mat-error *ngIf="pick_up_date.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form--item estimate__time__arrival">
        <label class="label">Estimated time of arrival
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <mat-select disableRipple [placeholder]="'ETA to your pick up place'"
              formControlName="estimated_time_of_arrival">
              <mat-option *ngFor="let time of estimateTimeArrival" [value]="time">{{time}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form--item note">
        <label class="label">
          note
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <textarea matInput
              placeholder="Please use this box if you have any question regarding your pick up process."
              formControlName="note" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
    <app-upload-document
    [subscription_type]="subscription_type"
    [payment_type]="payment_type"
    [pick_up_method]="pick_up_method"
    ></app-upload-document>
    <button class="submit__button submitted" (click)="submitAirport()">NEXT STEP</button>
  </div>
</div>
