<div class="residence">
  <div class="residence__informations">
    <h4 class="title">For condos, please make sure to include room number</h4>
    <p class="info">
      Please include name of
      resident or name on the mailbox / name plate. (name of the person the
      address is in care of ) Package will be delivered to your mailbox. Condos
      may have mailboxes at the door and entrance floor. Please check mailboxes
      at both locations.
    </p>
    <h4 class="title">Precautions for delivery to residential addresses.</h4>
    <p class="info">
      Please be informed following since it is almost the only issue occurred
      when delivering to residential addresses.
    </p>
    <p class="info">
      If post office suspects mis-delivery, they will put your item on-hold and
      require confirmation. In that case, you can contact us or post office for
      re-delivery. Re-delivery can be made within the same day or next morning.
      You can check status of your parcel with tracking service. We also check
      your item status and take actions if necessary.
    </p>
  </div>

  <div class="residence__form__content">
    <form class="residence__form" [formGroup]="residentInformation">
      <div class="form__item in__care">
        <label class="label">In care of</label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field">
            <input matInput formControlName="in_care_of" />
          </mat-form-field>
          <p class="hint__text">
            Resident name or name on the name plate/mail box should be
            sufficient.
          </p>
        </div>
      </div>

      <div class="form__item">
        <label class="label">Recipient<app-required></app-required></label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #recipientRef>
            <input matInput formControlName="recipient" (focus)="recipientChange($event)" required />
            <mat-error *ngIf="recipient.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form__item adress">
        <label class="label">Address <app-required></app-required></label>

        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #addressRef>
            <input matInput formControlName="residential_address" (focus)="addressChange($event)" required />
            <mat-error *ngIf="residential_address.hasError('required')">Required *</mat-error>
          </mat-form-field>
          <p class="hint__text" [ngClass]="{invalid : (residential_address.touched || residential_address.dirty) && residential_address.invalid}">
            <span class="bold">Please make sure to enter your FULL address, including room number, building name, etc.</span>
          </p>
        </div>
      </div>
      <div class="form__item">
        <label class="label">preferred date of pick up</label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" (click)="picker.open()">
            <input matInput placeholder="Select date" [min]="minDate" [matDatepicker]="picker"
              formControlName="preffered_date_of_pick_up" readonly/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <p class="hint__text">
            If you do not specify a desired delivery date, we will deliver your order as soon as possible.
          </p>
          <p class="hint__text">
            We might not be able to meet your request delivery date if you live
            in top-end or bottom-end of Japan. We will send you another email to
            inform you the scheduled delivery date.
          </p>
        </div>
      </div>

      <div class="form__item phone__number">
        <label class="label">Contact phone number</label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field">
            <input matInput formControlName="contact_phone_number" placeholder="Please enter your Japanese phone number"/>
          </mat-form-field>

        </div>
      </div>
      <div class="form__item note">
        <label class="label"> note </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <textarea matInput
              placeholder="Please use this box if you have any question regarding your pick up process."
              formControlName="note" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
