<div class="upload_only__container">
    <div class="upload_only__container__form">
      <form class="form__content" [formGroup]="ekyc_information">
        <div class="form--item pickup__date">
          <label class="label">
            Desired start date of use
            <app-required></app-required>
          </label>
          <div class="form__name">
            <mat-form-field appearance="standard" class="form--field" #pickupDateFF (click)="picker.open()">
              <input matInput placeholder="Select date"
              formControlName="pick_up_date" [matDatepicker]="picker"
                [min]="selectDate" required readonly/>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker (closed)="checkDatePickerEvent(picker)"></mat-datepicker>
              <mat-error *ngIf="pick_up_date.hasError('required')">Required *</mat-error>
            </mat-form-field>
          </div>
          <ul class="info important" [class.error-margin]="pick_up_date.hasError('required')">
            <li *ngIf="subscription_type === 'Voice + Data eSIM'">The eSIM's calling function can be used outside of Japan, but the data transmission function (data usage) is only available within Japan.</li>
            <li *ngIf="subscription_type !== 'Voice + Data eSIM'">The eSIM's data transmission function (data usage) is only available within Japan.</li>
            <li *ngIf="subscription_type !== 'Voice + Data eSIM'">The eSIM's SMS (text message) functionality is only available within Japan.</li>
            <li>Please note that if you specify the same day, the service may not be available depending on the time the application is submited.</li>
            <li>You will be notified of your eSIM preparation by email.</li>
          </ul>
        </div>
  
        <div class="form--item eid">
          <label class="label">
            EID 
          </label>
          <div class="form__name">
            <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
              <input matInput formControlName="device_eid_0" (focus)="deviceEIDChange($event)" maxlength="32"/>
            </mat-form-field>
            <mat-error *ngIf="device_eid_0.hasError('startsWith89')">The EID number should begin with '89'.</mat-error>
            <mat-error *ngIf="device_eid_0.hasError('minlength')">The EID number should be 32 digits long. Please ensure it is entered correctly.</mat-error>
          </div>
          <div *ngIf="quantity > 1" class="form__name">
            <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
              <input matInput formControlName="device_eid_1" (focus)="deviceEIDChange($event)" maxlength="32"/>
            </mat-form-field>
            <mat-error *ngIf="device_eid_1.hasError('startsWith89')">The EID number should begin with '89'.</mat-error>
            <mat-error *ngIf="device_eid_1.hasError('minlength')">The EID number should be 32 digits long. Please ensure it is entered correctly.</mat-error>
          </div>
          <div *ngIf="quantity > 2" class="form__name">
            <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
              <input matInput formControlName="device_eid_2" (focus)="deviceEIDChange($event)" maxlength="32"/>
            </mat-form-field>
            <mat-error *ngIf="device_eid_2.hasError('startsWith89')">The EID number should begin with '89'.</mat-error>
            <mat-error *ngIf="device_eid_2.hasError('minlength')">The EID number should be 32 digits long. Please ensure it is entered correctly.</mat-error>
          </div>
          <div *ngIf="quantity > 3" class="form__name">
            <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
              <input matInput formControlName="device_eid_3" (focus)="deviceEIDChange($event)" maxlength="32"/>
            </mat-form-field>
            <mat-error *ngIf="device_eid_3.hasError('startsWith89')">The EID number should begin with '89'.</mat-error>
            <mat-error *ngIf="device_eid_3.hasError('minlength')">The EID number should be 32 digits long. Please ensure it is entered correctly.</mat-error>
          </div>
          <div *ngIf="quantity > 4" class="form__name">
            <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
              <input matInput formControlName="device_eid_4" (focus)="deviceEIDChange($event)" maxlength="32"/>
            </mat-form-field>
            <mat-error *ngIf="device_eid_4.hasError('startsWith89')">The EID number should begin with '89'.</mat-error>
            <mat-error *ngIf="device_eid_4.hasError('minlength')">The EID number should be 32 digits long. Please ensure it is entered correctly.</mat-error>
          </div>
          <ul class="info normal">
            <li>EID is an identification number required to use eSIM provided by Japan's telecommunication company, NTT docomo. It is a unique 32-digit number that differs between devices.</li>
            <li>By registering the EID of the device using the eSIM, you will be able to configure the eSIM profile settings on the registered device.</li>
            <li>The eSIM cannot be used with a device whose EID differs from the registered EID.</li>
            <li>If you have already decided which device you will use the eSIM for, we strongly recommend that you enter your EID using this form, as this will shorten the time it takes to issue an eSIM after applying.</li>
            <li>If you are unsure which device you will use with the eSIM, you can also provide your EID after ordering the eSIM once you have decided which device you will use. </li>
          </ul>
          <ul class="info first_as_title">
            <li>Please see the information below for how to check your device's EID information.</li>
            <li>"iPhone:	Settings → General → About → EID"</li>
            <li>"Android:	Settings → About phone → EID Settings → Network & internet → SIMs → Add SIM → Download a new eSIM → Need help？ → Activating in a store? You may need this device's EID number. Find it here"</li>
          </ul>
        </div>
  
        <div class="form--item note">
          <label class="label">
            note
          </label>
          <div class="form__name">
            <mat-form-field appearance="standard">
              <textarea matInput
                placeholder="Please use this box if you have any question regarding your pick up process."
                formControlName="note" maxlength="250"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <app-upload-document
      [subscription_type]="subscription_type"
      [payment_type]="payment_type"
      [pick_up_method]="pick_up_method"
      [for_v_esim]="subscription_type === 'Voice + Data eSIM'"
    ></app-upload-document>
    <button class="submit__button submitted" (click)="submitEkyc()">NEXT STEP</button>
  </div>
  