<div class="terms__conditions">
  <header>
    <app-header [step]="3"></app-header>
  </header>
  <div class="container">
    <div class="container__header__title">
      <h1>Agree to terms and conditions of service</h1>
      <h2>and rental agreement</h2>
    </div>
    <div class="container__terms__conditions term">
      <h4 class="term__title">Terms and Condition of Service</h4>
      <div class="term__contents">
        <p class="term__contents__info">
          Please read all information carefully.
        </p>
        <p class="term__contents__title">
          Sakura Mobile Terms and Conditions of Service
        </p>
        <p class="term__contents__info">
          For rental agreements, please refer to Rental Agreement Revised Nov 5, 2020 Welcome to Sakura Mobile. We are
          pleased to be your wireless service provider. We reserve the right to change or modify these Terms and
          Conditions of Service (“Ts&Cs”) at any time, effective immediately when posted to Sakura Mobile’s website at
          www.sakuramobile.jp. Please check our website from time to time as these Ts&Cs may have changed. We will
          attempt to contact you by email if necessary, but do not guarantee that you will receive the email and take no
          responsibility for any issues receiving the email. Please read these terms and conditions carefully and make
          sure that you understand each provision as they contain important information about the services provided to
          you. These terms and conditions require the use of arbitration on an individual basis to resolve disputes,
          rather than jury trials or class actions. These terms and conditions limit our liability and the remedies
          available to you in the event of a dispute.
        </p>
        <p class="term__contents__title">
          General Definitions
        </p>
        <p class="term__contents__info">
          In these Ts&Cs the following terms have the following meanings: (a) “Sakura Mobile,” “we,” “us,” and “our”
          refer to Sakura Mobile where operated by Og inc. ; (b) “you,” “your,” “user,” “subscriber,” or “customer”
          refer to the account holder or any other person who purchases, activates or uses the Wireless Services we
          provide; (c) “Device” means any phone, smartphone, tablet, SIM card, data card, and any other device,
          equipment, accessory, or other product that we provide or sell to you, or that is assigned to, activated on,
          or used with your account; (d) “Service(s)” or “Wireless Service(s)” means any wireless services we provide or
          that reference these Ts&Cs and includes, but is not limited to, our rate and service plans, billing services,
          offers, promotions, applications, programs, products, features, software, or any Device on your account; (e)
          “Charges” means any access and usage charges, taxes, surcharges, fees, including government fees (whether
          assessed directly upon you or upon Sakura Mobile) and other charges we charge you or that were accepted or
          processed through your Device as further defined below in the “Charges” section of these Ts&Cs.
        </p>
        <p class="term__contents__title">
          Wireless Customer Agreement
        </p>
        <p class="term__contents__info">
          These Ts&Cs are part of your Wireless Customer Agreement (“Agreement”) and form a contract between you and
          Sakura Mobile that applies to all Devices and Wireless Services provided to you. Your Agreement with us also
          includes: (a) our Privacy Policy available at www.sakuramobile.jp/privacy-policy; (b) our rate plans, and
          other transaction materials, including any receipt or service summary that you sign, accept, or that is
          provided to you, and any transaction confirmation materials we may provide to you; (c) any terms and
          conditions for wireless products, services, features, promotions, and applications not otherwise described in
          these Ts&Cs that we provide to you or that we make available on Sakura Mobile’s website at www.sakuramobile.jp
          (collectively “Supplemental Materials”); and, (d) our Policies (as defined below). In the event of any
          conflict between these Ts&Cs and any Supplemental Materials or Policies, these Ts&Cs shall control.
        </p>
        <p class="term__contents__title">
          Acceptance of the Ts&Cs
        </p>
        <p class="term__contents__info">
          You represent that you are fulfilled the adult age that is described by the Japanese Government on Civil Law,
          “Civil Code Article 4: Age of Majority” and/or that you are legally authorized to accept these Ts&Cs and to
          enter into this Agreement. Including under-age individuals with a valid Sakura Mobile parental consent form
          (provided by us) signed by their legal representative. Your Agreement with Sakura Mobile begins when you
          accept these Ts&Cs by doing any of the following: (a) giving us a written or electronic signature or telling
          us orally that you accept, or by otherwise accepting through any other printed, oral, or electronic means; (b)
          paying for Service; (c) activating the Service; (d) using or attempting to use the Service in any way; (e)
          upgrading or modifying the Service; or (f) opening any Device packaging, or starting any application, program
          or software that states you are accepting. If you do not want to accept these Ts&Cs, do not take any of these
          actions.
        </p>
        <p class="term__contents__title">
          Policies
        </p>
        <p class="term__contents__info">
          Services are subject to our business policies, practices, and procedures (“Policies”), including our
          Acceptable Use Policy. You agree to follow our Policies when you use our Services. Our Policies are subject to
          change at any time and become effective when posted to Sakura Mobile’s website at www.sakuramobile.jp.
        </p>
        <p class="term__contents__title">
          Other Ts&Cs
        </p>
        <p class="term__contents__info">
          Other terms and conditions, including end-user license agreements, apply to certain applications, software,
          products, programs, services, promotions, and features that we provide to you, that you obtain from a third
          party through us, or that you obtain directly from a third party. These other terms and conditions may be
          significantly different from the Ts&Cs that are part of your Agreement. It is your responsibility to read and
          understand any other terms and conditions before you accept them. We are not responsible for any third party
          terms and conditions.
        </p>
        <p class="term__contents__title">
          Our Right to Change Terms and Rates
        </p>
        <p class="term__contents__info">
          We may change any part of the Agreement at any time, including but not limited to these Ts&Cs, Supplemental
          Materials, rates, rate plans, features, products, fees, expenses, coverage areas, roaming partners,
          international rates, Sakura691 rates, provisioning technology, and Charges for your Service. Changes become
          effective when posted on Sakura Mobile’s website at www.sakuramobile.jp. We will attempt to provide you with
          notice of material changes by email, but do not guarantee that you will receive notice of any changes. If you
          continue to access or use our Services on or after the effective date of a change, you accept any change. If
          you continue to access or use our Services on or after the effective date of a change, you accept any change.
          Do not access or use our Services after the effective date of a change if you decide to reject the change and
          cancel Service. You will not be entitled to any refund if you decide to cancel Service.
        </p>
        <p class="term__contents__title">
          Termination
        </p>
        <p class="term__contents__info">
          Either party may terminate this Agreement (which will end your Service with Sakura Mobile) at any time after
          the minimum usage specified by each plan, and for any reason. You are responsible for all relevant Charges
          until your account is completely paid and canceled. You may also request that we transfer (“port”) your number
          to another carrier and Service for that number will be canceled when the porting is complete. We reserve the
          right to charge a fee if you request to port your number. You are responsible for all Charges until the end of
          the same month as the port-out is completed. We do not guarantee that the number transfers to or from us will
          be successful. You will not receive any refund or credit for the unused portion if you decide to cancel
          Service. You agree that we may, in our sole discretion, without limitation and without notice, limit,
          interrupt, suspend or cancel your Service for any reason, including, but not limited to, (i) any conduct that
          we believe violates the Agreement, these Ts&Cs, or our Policies, (ii) your failure to fulfill the payment on
          time that is indicated by each payment method, (iii) if you behave in an abusive, derogatory or similarly
          unreasonable manner with any of our representatives, or (iv) if we have reason to believe that your Device is
          being used for any unlawful purpose or in a way that may adversely affect our Service.
        </p>
        <p class="term__contents__title">
          Service Plans
        </p>
        <p class="term__contents__info">
          Sakura Mobile offers monthly service plans for basic phones and smartphones (“Service Plans”). Unless
          otherwise prohibited by your specific plan terms or type of device, we will confirm one service plan change
          per month (As long as the plan change request form is submitted by the 25th of the month for service plan
          changes that start the following month.), although we may prohibit or restrict any plan change we believe is
          in violation of these Ts&Cs or any applicable Policy. In addition, we reserve the right to change the Service
          Plan you are on if your device is not supported by the Service Plan you selected. Some Service Plan changes
          may be conditioned on the payment of certain charges, and, depending on your plan, you are responsible for the
          remaining Service Plan balance if you change Service Plans. Information on our Service Plans is available at
          www.sakuramobile.jp.
        </p>
        <p class="term__contents__title">
          Charges
        </p>
        <p class="term__contents__info">
          You are responsible for paying all Charges for Services provided under this Agreement, including Charges made
          by any person you permit to have direct or indirect access to your Device even if you did not authorize its
          use. These include, but are not limited to, (a) monthly access charges; (b) usage charges (if your plan
          includes metered usage); (c) charges for features and add-ons; (d) taxes, fees, and other assessments imposed
          by the government that we are required to collect and remit to the government (you are required to provide us
          with your street address); (e) other fees and charges including activation, reactivation, prepayment,
          convenience payment, restocking, Device unlocking, SIM replacement, number change, express delivery, upgrade,
          program, or other fees, including specific transaction fees, relating to Services and Devices purchased from
          us; and (f) surcharges that we collect and retain from our customers that include, but are not limited to,
          government fees, regulatory charges, administrative fees, and government taxes or fees imposed on gross
          receipts, sales and/or property that we incur in providing Services to our customers. Surcharges are not
          taxes, and we are not required by the government to collect them from you. We determine the rate of these
          charges, and the amounts are subject to change.
        </p>
        <p class="term__contents__title">
          Billing
        </p>
        <p class="term__contents__info">
          You will not have access to detailed usage records for most Services. You may access your account on our
          portal website via your Device, PC, or tablet. We will attempt to send you account status updates if
          necessary, but we cannot guarantee that you will receive them or that you will receive them before your
          account is suspended or canceled.
        </p>
        <p class="term__contents__title">
          Payments and Account Balance
        </p>
        <p class="term__contents__info">
          The availability of Services depends upon your payment in advance of all Charges when they are due. If you
          fail to make payments when due, the Services will be suspended and/or canceled. By making a payment you will
          have access to Services for a limited amount of time and you must use the Services during the designated
          period of time; depending on the contract and plan, unused allotment of services roll-over; any unused
          allotment of Pocket WIFI Services from one designated period of time will not carry over to the next period
          (e.g., unused data usage does not roll-over) Charges for our Services and the amount of time Services are
          available following activation may vary. If your account is deactivated for nonpayment it will be placed in
          suspend mode starting 10 days after the payment has been due. If your account balance is not paid before 5
          days after the suspension period begins, it will be canceled. Amounts paid for Service Charges are
          non-refundable and account balances are not transferable, refundable, or redeemable for cash. If your account
          is suspended or canceled, for any reason, any deposit (if paid) will be used to cover any remaining charges
          (including unpaid bills, damages, lost fees, etc.). When your account is canceled we will re-assign the phone
          number associated with your account, and that phone number or identification ID associated with your account
          may no longer be available to you. We may also charge you a fee to reactivate Service depending on when you
          reactivate.
        </p>
        <p class="term__contents__title">
          Account Access
        </p>
        <p class="term__contents__info">
          In order to protect the security of your account, you should set your password as soon as possible after your
          account is activated. You agree to protect your password and other account access credentials from loss or
          disclosure. You authorize us to provide information about and to make changes to your account, including the
          purchase of products and/or Services, upon the direction of any person able to provide information we deem
          sufficient to identify you. You consent to the use by us or our authorized agents of regular mail, predictive
          or auto-dialing equipment, email, text messaging, facsimile, or other reasonable means to contact you to
          advise you about our Services or other matters we believe may be of interest to you. We may contact you by any
          means regarding customer service-related notifications, or other such information.
        </p>
        <p class="term__contents__title">
          Devices
        </p>
        <p class="term__contents__info">
          Sakura Mobile does not manufacture any Device that we sell or that can be used with our Services and we are
          not responsible for any defects, acts, or omissions of the manufacturer of any Device. The only warranties on
          your Device are the limited warranties provided by the manufacturer (WITH RESPECT TO WHICH WE HAVE NO
          LIABILITY WHATSOEVER). Your Device must be compatible with and not interfere with our Services and must comply
          with all applicable laws, rules, and regulations. You may use your own Device with our Service (“Own Device”),
          but it must be compatible with and must not harm our network; if you use your Own Device, certain network and
          Device features and functionality may not be available and/or may not function properly with your Own Device.
          We reserve the right to prevent you from using any Device on our network. Device performance may vary based on
          your Device specifications including processor capabilities, software, memory, and storage, and Device
          performance that may impact access to all of our Services. We may periodically program Devices on our network
          remotely with software, system settings, applications, features, or programming, without notice, either prior
          to, during, or after service activation. These changes will modify your Device and may affect or erase data
          you have stored on your Device, the way you have programmed your Device, or the way you use your Device. You
          may be unable to use your Device until the remote programming is complete, even in an emergency. You also
          agree that you will not make, nor will you assist others to make, any modifications to any Sakura Mobile
          Device or programming to enable it to operate on any other system or network. You understand and acknowledge
          that Sakura Mobile Devices are rented solely for use with our network and that we will be significantly
          damaged if you use or assist others to use our Devices for any other purpose. You also agree not to take any
          action to circumvent limits on the quantity of Sakura Mobile Devices that may be rented. You will be liable to
          Sakura Mobile for any damages resulting from conduct prohibited in this section.
        </p>
        <p class="term__contents__title">
          Information on Your Device
        </p>
        <p class="term__contents__info">
          Your Device and SIM card may contain sensitive personal information (such as financial, healthcare,
          government-issued information and identification numbers, credit card numbers, pictures, videos) that you may
          wish to protect and not disclose to others. Sakura Mobile is not responsible for any of the information on
          your Device. You must remove any sensitive personal information from your Device before you return, exchange,
          recycle, or otherwise give up control of your Device. Information may remain on your device even if you remove
          the SIM card. Information left on your Device will be accessible to others who use your Device.
        </p>
        <p class="term__contents__title">
          Lost or Stolen Devices
        </p>
        <p class="term__contents__info">
          You must contact us immediately to report a Device that is lost or stolen. You are responsible for all Charges
          incurred on your phone number until you report the theft or loss to us. After you report the theft or loss,
          you remain responsible for complying with your obligations under this Agreement including the payment of any
          monthly Service fees. You further agree to cooperate in the investigation of fraud or theft and to provide us
          with such requested information and documentation (including any proof of loss and police reports) as we may
          reasonably request.
        </p>
        <p class="term__contents__title">
          Availability of Service and Coverage
        </p>
        <p class="term__contents__info">
          Services may be interrupted, delayed, or otherwise limited for a variety of reasons, including environmental
          conditions, unavailability of radio frequency channels, system capacity and constraints, priority access by
          National Security and Emergency Preparedness personnel in the event of a disaster or emergency, coordination
          with other systems, equipment modifications and repairs, and problems with the facilities of interconnecting
          carriers. We do not guarantee the availability of the wireless network or any Wireless Services. You will not
          have access to our network outside of our coverage areas. Coverage maps, available from us, show a high-level
          approximation of wireless coverage and there are gaps in coverage not shown by this approximation; actual
          coverage may vary and be affected by terrain, weather, foliage, buildings, signal strength, high-usage
          periods, customer equipment and other factors. We do not guarantee coverage and the maps supplied by the
          network carriers Sakura Mobile uses do not show actual network coverage or performance.
        </p>
        <p class="term__contents__title">
          Airtime Usage
        </p>
        <p class="term__contents__info">
          If your Service includes measured voice usage, your airtime is charged in 30-second increments, and actual
          usage is rounded up to the next 30-second increment at the end of each call. We charge a 30-second of airtime
          usage for every fraction of the 30-second of airtime used. Airtime begins for outgoing calls when you press
          SEND (or similar key) and for incoming calls when a signal connection from the caller is established with our
          facilities. Airtime ends after you press END (or similar key), but not until the disconnect signal is received
          by our facilities and confirmed. All outgoing calls for which we receive answer supervision or which have at
          least 30 seconds of airtime, including ring time, will incur a minimum of 30-second airtime charge. Answer
          supervision is generally received when a call is answered; however, answer supervision may also be generated
          by voicemail systems, private branch exchanges, and interexchange switching equipment. Airtime may include
          time for us to recognize that only one party has disconnected from the call, time to clear the channels in
          use, and ring time. Airtime may also occur from other uses of our facilities, including voicemail deposits and
          retrievals, and call transfers. Calls that begin in one rate period and end in another rate period may be
          charged during the period in which the call began. For overseas usage of Sakura Mobile products, airtime usage
          will be charged in full-minute increments.
        </p>
        <p class="term__contents__title">
          Data Services – Permitted Uses
        </p>
        <p class="term__contents__info">
          Sakura Mobile provides wireless data and messaging Services, including, but not limited to, features that may
          be used with data services and wireless content and applications (“Data Services”). Our Data Services are
          intended to be used for the following permitted activities: (a) web browsing; (b) email; (c) internet access;
          (d) uploading and downloading ordinary applications and content to and from the Internet; and (e) using
          applications and content without excessively contributing to network congestion. You agree that Sakura Mobile
          may engage in any reasonable network management practice and that you will use Sakura Mobile’s Data Services
          only for these permitted activities.
        </p>
        <p class="term__contents__title">
          Data Services – Applications and Content
        </p>
        <p class="term__contents__info">
          Our Data Services may allow you to access the Internet where you will have access to text, pictures, video,
          games, graphics, music, email, applications, sound, and other materials primarily from third party content
          providers unaffiliated with Sakura Mobile (“Data Content”) and where you will have the ability to send,
          receive and share Data Content. You may also purchase Data Content with your Device from us or from third
          parties. Sakura Mobile is not a publisher of third party Data Content and is not responsible for any opinions,
          advice, statements, or other information, services, or goods provided by third parties. Third-party Data
          Content may among other things be offensive, indecent, violent, pornographic, inappropriate, false and
          misleading, inaccurate, unreliable, and unsuitable for children and minors – you are solely responsible for
          any third party Data Content accessed through our Data Services. The accuracy, appropriateness, content,
          completeness, timeliness, usefulness, security, safety, merchantability, fitness for a particular purpose,
          transmission, or correct sequencing of any application, information, or downloaded data is not guaranteed or
          warranted by Sakura Mobile or any content providers or other third party. Neither Sakura Mobile nor its
          content providers, service providers, or other third parties shall be liable to you for any loss or injury
          arising out of or caused, in whole or in part, by your use of any information, application or content, or any
          information, application, or other content acquired through our Data Services. Third-party Data Content may
          harm your Device and its software, and we are not responsible for any third party Data Content that you
          download, install, or use with our Data Services.
        </p>
        <p class="term__contents__title">
          Data Usage
        </p>
        <p class="term__contents__info">
          We measure data usage by the kilobyte or the megabyte or gigabyte, where 1024 kilobytes equals 1 megabyte, and
          1024 megabytes equals 1 gigabyte. To determine your data usage, we calculate in full-kilobyte increments, and
          actual usage is rounded up to the next full kilobyte increment at the end of each data session. Data usage
          occurs whenever your Device is connected to our network and is engaged in any data transmission. This includes
          among other things, sending and receiving email, texts, documents, and content; accessing websites; and
          downloading and using applications and content. Some applications, content, programs, and software that you
          download or that come pre-loaded on your Device automatically and regularly send and receive data
          transmissions without any action by you and without your knowledge. For example, applications that provide
          real-time information and location-based information connect to our network and continuously send and receive
          updated information. In addition, advertisements or advertiser-related messages or data delivered to your
          Device, even if delivered to an application, as well as any messages or content initiating a response to an
          advertisement, will count toward your data usage. Network overhead, software update requests, email
          notifications, and resend requests caused by network errors can increase data usage. You will be charged for
          all data usage when your Device is connected to our network, whether you initiate the connection or it runs
          automatically in the background and whether successful or not.
        </p>
        <p class="term__contents__title">
          Rate Plans
        </p>
        <p class="term__contents__info">
          Sakura Mobile’s Wireless Service rate plans offer voice calls, SMS, and data service reflected in the contract
          you signed up for. After your high-speed data access allowance is used (if applicable), your data download
          speeds will be reduced to a low-speed mode with speeds differing between products for the rest of your billing
          cycle. For all Sakura Mobile plans, high-speed data access allows download speeds of a maximum of 20 Mbps for
          compatible 4G LTE devices and 3 Mbps for compatible 4G HSPA+ devices.
        </p>
        <p class="term__contents__title">
          Unlimited Data
        </p>
        <p class="term__contents__info">
          You agree that “unlimited” means you pay a single monthly flat rate that includes wireless data service
          regardless of how much data you use in a billing cycle. You further agree that “unlimited” does not mean that
          you can use Sakura Mobile’s wireless data service in any way that you choose or for any prohibited uses and
          that if you use your unlimited data plan in any manner that is prohibited, Sakura Mobile can limit, interrupt,
          suspend or cancel your Service. Any unlimited plan we may offer is subject to these Ts&Cs, including the
          restrictions against prohibited uses and misuse and abuse of our Services and Devices, any reasonable network
          management practices, and our Acceptable Use Policy. Plans are also subject to network carriers’ Fair Usage
          Policy (FUP), which ensures a stable network for all users by restricting the data transmission of users
          deemed to be using excessive amounts of data hindering network usage of other users.
        </p>
        <p class="term__contents__title">
          Location-Based Services
        </p>
        <p class="term__contents__info">
          Sakura Mobile collects information about the approximate location of your Device in relation to our cell
          towers and the Global Positioning System (“GPS”). We use that information, as well as other usage and
          performance information also obtained from our network and your Device, to provide you with wireless voice and
          data services, including services which may be provided by third parties, and to maintain and improve our
          network and the quality of your wireless experience. We may also use location information to create aggregate
          data consistent with our Privacy Policy. It is your responsibility to notify users on your account that we may
          collect and use location information from Devices. Your Device is also capable of using optional content at
          your request or the request of a user on your account, offered by Sakura Mobile or third parties that make use
          of your Device location information (“Location-Based Services”). Please review the terms and conditions and
          Sakura Mobile’s privacy policy for each Location-Based Service to learn how the location information will be
          used and protected. For more information on Location-Based Services, please visit www.sakuramobile.jp/privacy.
        </p>
        <p class="term__contents__title">
          Software
        </p>
        <p class="term__contents__info">
          The software, interfaces, documentation, data, and content provided for your Device may be updated,
          downloaded, or replaced by feature enhancements, software updates, system restore software, or data generated
          or provided subsequently by Sakura Mobile (hereinafter “Software”) is licensed, not sold, to you by Sakura
          Mobile and/or its licensors/suppliers for use only on your Device. You are not permitted to use the Software
          in any manner not authorized by this license. You may not (and you agree not to enable others to) copy,
          decompile, reverse engineer, disassemble, reproduce, attempt to derive the source code of, decrypt, modify,
          defeat protective mechanisms, combine with other software, or create derivative works of the Software or any
          portion thereof. You may not rent, lease, lend, sell, redistribute, transfer or sublicense the Software or any
          portion thereof. You agree the Software contains proprietary content and information owned by Sakura Mobile
          and/or its licensors/suppliers. Sakura Mobile and its licensors/suppliers reserve the right to change,
          suspend, terminate, remove, impose limits on the use or access to, or disable access to, the Software at any
          time without notice and will have no liability for doing so. You acknowledge Sakura Mobile’s Software
          licensors/suppliers are intended third-party beneficiaries of this license, including the indemnification,
          limitation of liability, disclaimer of warranty provisions found in this Agreement.
        </p>
        <p class="term__contents__title">
          Intellectual Property
        </p>
        <p class="term__contents__info">
          You must respect the intellectual property rights of Sakura Mobile, our third-party content providers, and any
          other owner of intellectual property whose protected property may appear on any website and/or dialogue box
          controlled by Sakura Mobile or accessed through Sakura Mobile’s websites. Except for material in the public
          domain, all material displayed in association with our Services is copyrighted or trademarked. Except for
          personal, non-commercial use, trademarked and copyrighted material may not be copied, downloaded,
          redistributed, modified, or otherwise exploited, in whole or in part, without the permission of the owner.
        </p>
        <p class="term__contents__title">
          Prohibited Uses and the Misuse and Abuse of Services and Devices
        </p>
        <p class="term__contents__info">
          When you purchase, activate, or use our Services or Devices you agree that you will not misuse or abuse our
          Services or Devices by doing, among other things, any of the following: (a) renting a Device without intending
          to activate or use it on our network; (b) reselling or rebilling our Services, or reselling Sakura Mobile
          Devices; (c) modifying Sakura Mobile’s Device from its manufacturer’s specifications; (d) activating a Device
          on a Service Plan or with a feature or product not designated for its use; (e) using our Services or Devices
          for any fraudulent or unlawful purpose; (f) using voice services as monitoring services, for data
          transmissions, the transmission of broadcasts, the transmission of recorded material, or other connections
          which do not consist of uninterrupted live dialog between two individuals; or (g) using any Service in a
          manner that is burdensome or unusually excessive when compared to other customers in your area on similar
          plans. You agree that you will not use Data Services in any manner which: Conflicts with applicable law;
          compromises network security or capacity; adversely impacts network service levels or legitimate data flows;
          degrades network performance; causes harm to the network or other customers; is resold either alone or as part
          of any other good or service; tethers a wireless Device to a computing device (such as a computer, Smartphone,
          eBook or eReader, media player, laptop, or other devices with similar functions) through use of connection
          kits, applications, devices or accessories (using wired or wireless technology) and you have not subscribed to
          a Service Plan or feature designed for this purpose; or there is a specific Service Plan or feature for a
          particular use and you have not subscribed to that Service Plan or feature. Sakura Mobile’s Data Services may
          not be used in any manner that defeats, obstructs or penetrates, or attempts to defeat, obstruct or penetrate
          the security measures of our wireless network or systems, or another entity’s network or systems; that
          accesses, or attempts to access without authority, the accounts of others; or that adversely affects the
          ability of other people or systems to use Sakura Mobile’s wireless services or other parties’ Internet-based
          resources. For example, this includes, but is not limited to, malicious software or “malware” that is
          designed, intentionally or unintentionally, to infiltrate a network or computer system such as spyware, worms,
          Trojan horses, rootkits, and/or crimeware; “denial of service” attacks against a network host or individual
          user; and “spam” or unsolicited commercial or bulk email (or activities that have the effect of facilitating
          unsolicited commercial email or unsolicited bulk email). Sakura Mobile’s Data Services may not be used in any
          manner that has the effect of excessively and disproportionately contributing to network congestion, hindering
          other customers’ access to the network, or degrading network performance by maintaining a sustained or
          continuous wireless data service connection or active wireless Internet connection. For example, this
          includes, but is not limited to, server devices or host computer applications such as continuous Web camera
          posts or broadcasts, automatic data feeds, or automated machine-to-machine connections; “auto-responders,”
          “cancel-bots,” or similar automated or manual routines that generate excessive amounts of traffic or that
          disrupt user groups or email use by others; use of data services as a substitute or backup for private lines
          or full-time or dedicated data connections; peer-to-peer (P2P) file-sharing services; and software or other
          devices that maintain continuously active Internet connections when a connection would otherwise be idle or
          any “keep-alive” functions. Sakura Mobile’s Data Services may not be used with high bandwidth applications,
          services, and content not optimized to work with Sakura Mobile’s wireless data services and, therefore
          disproportionately and excessively contribute to network congestion. This includes, but is not limited to,
          redirecting television signals for viewing on computing devices, web broadcasting, and/or the operation of
          servers, telemetry devices, or supervisory control and data acquisition devices. You agree not to engage in
          conduct toward our employees, customers, or others that involves the use of abusive, derogatory, insulting,
          threatening, vulgar, offensive, or similarly unreasonable language or behavior; engage in conduct that is
          harmful to, interferes with, damages, or adversely affects our network and infrastructure, property, Services,
          Devices, operations, customers, reputation, intellectual property rights, or that results in the publication
          of threatening, offensive or illegal material, or generates spam or other abusive messaging or calling, a
          security risk, or a violation of privacy; or violate, in any way, these Ts&Cs, the Agreement, or our Policies.
        </p>
        <p class="term__contents__title">
          Network and Service Protective Measures
        </p>
        <p class="term__contents__info">
          We may, at our sole discretion, take any action to protect and maintain the integrity of our network, our
          rights and property, our Services, or the rights and interests of others, including, but not limited to (a)
          modifying, without advance notice, the permitted and prohibited data activities, and the optimization
          requirements for your Data Services; (b) engaging in any reasonable network management practice to enhance
          customer service, to reduce network congestion, to adapt to advances and changes in technology, and/or to
          respond to the availability of wireless bandwidth and spectrum; (c) reducing your data throughput speeds at
          any time or place if your data usage exceeds an applicable usage threshold; (d) using reasonable methods to
          monitor and collect customer usage information to better optimize the operation of the network; (e) changing
          the Service Plan you selected to a Service Plan identified by Sakura Mobile as appropriate for the device
          being used; (f) interrupting, suspending, canceling or terminating your Wireless Services without advance
          notice; (g) blocking access to certain categories of numbers (e.g., premium rate numbers and international
          destinations) at our sole discretion.
        </p>
        <p class="term__contents__title">
          Disclaimer of Warranties
        </p>
        <p class="term__contents__info">
          Sakura mobile makes no representations or warranties, express or implied, including (to the extent allowed by
          law), without limitation, any implied warranty of merchantability or fitness for a particular purpose,
          suitability, accuracy, security, or performance regarding our wireless services, devices, applications,
          software, or other services subject to this agreement. We do not promise or guarantee uninterrupted or
          error-free service or coverage. And we cannot assure you that if you place a 110 call you will be found.
        </p>
        <p class="term__contents__title">
          Limitations on Service and Liability
        </p>
        <p class="term__contents__info">
          You agree that, unless prohibited by law, the following limitations of service and liability apply and that
          neither we nor our parent companies, subsidiaries, affiliates, vendors, suppliers, or licensors are
          responsible for any damages resulting from: (a) any act or omission of a third party; (b) mistakes, omissions,
          interruptions, errors, failures to transmit, delays, or failures or defects in the Services or Software or
          Devices provided by or through us; (c) damage or injury caused by the use of Services, Software, or Device,
          including while used in a vehicle; (d) claims against you by third parties; (e) damage or injury caused by a
          suspension or termination of Services or Software; (f) damage or injury caused by an interruption or failure
          in accessing or attempting to access emergency services from a Device, including through 110 or any other
          emergency service; (g) any health-related claims relating to our Services or Devices; (h) data content or
          information accessed while using our Services; (i) loss or disclosure of any sensitive information you
          transmit; (j) interrupted, failed, or inaccurate location information services; (k) information or
          communication that is blocked by a spam filter; (l) damage to your Device or any computer or equipment
          connected to your Device, or damage to or loss of any information stored on your Device, computer, equipment,
          or Sakura Mobile storage space, including damage or loss from your use of the Services or from viruses, worms,
          or downloads of malicious content, software, materials, data, text, images, video, or audio; or (m) things we
          cannot control, including acts of God (such as hurricanes, tornados, fire, earthquake, etc.) riot, strike,
          war, terrorism, or government action.
        </p>
        <p class="term__contents__title">
          Limitation of Liability – No Consequential Damages
        </p>
        <p class="term__contents__info">
          UNLESS PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES IS SAKURA MOBILE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          PUNITIVE, MULTIPLE, SPECIAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE WHATSOEVER YOU OR ANY THIRD PARTY
          MAY SUFFER BY USE OF, OR INABILITY TO USE, SERVICES, SOFTWARE, OR DEVICES PROVIDED BY OR THROUGH Sakura
          Mobile, INCLUDING LOSS OF BUSINESS OR GOODWILL, REVENUE OR PROFITS, OR CLAIMS OF PERSONAL INJURIES. THIS
          LIMITATION AND WAIVER ALSO APPLIES IF YOU BRING A CLAIM AGAINST ONE OF OUR SUPPLIERS IF WE WOULD BE REQUIRED
          TO INDEMNIFY THE SUPPLIER FOR THE CLAIM.
        </p>
        <p class="term__contents__title">
          Indemnification
        </p>
        <p class="term__contents__info">
          You agree to indemnify, defend and hold Sakura Mobile (including its officers, directors, and employees) and
          our parent companies, affiliates, vendors, suppliers, and licensors harmless from and against any and all
          claims for damages of any nature arising in any way from or relating to, directly or indirectly, the Services
          or Devices provided by Sakura Mobile or any person’s use thereof (including, but not limited to, vehicular
          damage and personal injury), INCLUDING CLAIMS ARISING IN WHOLE OR IN PART FROM SAKURA MOBILE’S ALLEGED
          NEGLIGENCE or any violation by you of this Agreement.
        </p>
        <p class="term__contents__title">
          Disputes
        </p>
        <p class="term__contents__info">
          Within 30 days of the date of any event giving rise to a dispute, you must notify us in writing at the office
          of dispute resolution, Sakura Mobile, Zenken Plaza II 6th Fl. 1-3-13, Nishishinjuku, Shinjuku-ku,
          Tokyo, 160-0023, Japan of such dispute, including any dispute over charges or service we provided, or you will
          have waived your right to dispute the charges or services and to bring or participate in any legal action
          raising such dispute.
        </p>
        <p class="term__contents__title">
          Other Important Terms and Provisions
        </p>
        <p class="term__contents__info">
          ASSIGNMENT: Sakura Mobile may assign this Agreement; but, you may not assign this Agreement without our prior
          written consent. SEVERABILITY: If any provision of this Agreement is found to be unenforceable by a court or
          agency of competent jurisdiction, the remaining provisions will remain in full force and effect. The foregoing
          does not apply to the prohibition against class or representative actions that is part of the arbitration
          clause; if that prohibition is found to be unenforceable, the arbitration clause (but only the arbitration
          clause) shall be null and void. CHOICE OF LAW: The law of Japan shall govern this Agreement. In the event of a
          dispute between us, the law of Japan, whether in litigation or arbitration, shall govern. ENGLISH LANGUAGE:
          The original version of this Agreement is in the English language. Any discrepancy or conflicts between the
          English version and any other language version will be resolved with reference to and by interpreting the
          English version. ENTIRE AGREEMENT: This Agreement constitutes the entire agreement between you and Sakura
          Mobile, and supersedes any and all prior agreements and understandings relating to the subject matter of this
          Agreement. SURVIVAL: Those provisions of these Ts&Cs that would, by their very nature, continue beyond the
          termination of Services (including dispute resolution by binding arbitration, warranty disclaimers,
          limitations on liability), shall survive termination of Services.
        </p>
      </div>
    </div>

    <div class="container__terms__conditions agreenment">
      <h4 class="term__title">Rental Agreement</h4>
      <div class="term__contents">
        <p class="term__contents__info">
          Please read all information carefully.
        </p>
        <!-- Rental -->
        <ol class="inherit">
          <li>
            General Terms and Conditions
            <ul>
              <li>
                Under the Terms and Conditions for Rental provided hereunder (hereinafter referred to as “Terms and
                Conditions”), Og inc. (hereinafter referred to as “Company”) shall rent a data access line and related
                equipment (hereinafter referred to as “Rental Equipment”) to a customer who desires to rent from the
                Company (hereinafter referred to as “Renter”). Particulars, not provided in the Terms and Conditions,
                shall be in accordance with the laws, regulations, and general customs.
              </li>
              <li>
                The Company may enter into any special contract, provided that it is not contrary to the purport of the
                Terms and Conditions, the laws, the administrative notices, and general customs. In the event special
                contracts are entered into, such special contracts shall prevail over the Terms and Conditions.
              </li>
              <li>
                Under no circumstances shall the Company be liable for incidental damages resulting from late or
                malfunctioning equipment. The Company’s liability is limited to the shipping charges and rental fees
                paid on Rental Agreements.
              </li>
            </ul>
          </li>
          <li>
            Reservations
            <ol class="inherit">
              <li>
                Making Reservations
                <ul>
                  <li>
                    Provided that the Terms and Conditions and the price list as set forth separately, the Renter can,
                    by the method provided separately, make reservations (hereinafter referred to as “Reservation”) by
                    specifying in advance the Rental Equipment, the commencement date of rental, the period of rental,
                    the Renter’s name(s), use or non-use of accessories, and other rental conditions, if any,
                    (hereinafter referred to as “Renting Conditions”) required for the Rental service.
                  </li>
                  <li>
                    In the event that the Renter desires to change any of the Renting Conditions, the Renter shall
                    obtain the consent of the Company in advance.
                  </li>
                  <li>
                    The Company will notify the Renter once the Reservation is accepted. Reservation is not made and not
                    effective without the proper notice.
                  </li>
                </ul>
              </li>
              <li>
                Cancellations of Reservations
                <ul>
                  <li>
                    The Company may cancel the Reservation without any notification or whatsoever.
                  </li>
                  <li>
                    The Renter can cancel the Reservations by the method provided separately.
                  </li>
                </ul>
              </li>
              <li>
                Exemption
                <ul>
                  <li>
                    Except as otherwise stated in Articles 2.1 and 2.2, the Company and the Renter shall make no claims
                    whatsoever to each other, with respect to canceling of reservations and non-execution of the Rental
                    Agreement.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Rental Agreements
            <ol class="inherit">
              <li>
                Execution of Rental Agreements
                <ul>
                  <li>
                    While the Renter shall specify the Renting Conditions as set forth in the reservation, the Company
                    shall specify the Equipment renting conditions as set forth in the Terms and Conditions, the price
                    list, etc., before the Rental Agreement is executed.
                  </li>
                  <li>
                    Upon the execution of the Rental Agreement, the Renter shall pay to the Company the Rental Charge,
                    as set forth in the Reservations.
                  </li>
                  <li>
                    The Rental Agreement has to be made in the method stipulated by the Company and has to be validated
                    in the method stipulated by the Company.
                  </li>
                  <li>
                    Only e-mails from @sakuramobile.jp or @o-g.co.jp or paper documents certified with the Company’s
                    stamp (hereinafter referred to as “Valid Forms of Contact”) are used by the Company and are
                    considered valid.
                  </li>
                  <li>
                    The Rental Agreement shall come into effect only after the Renter pays the Rental Charge and
                    deposits, if required, in advance (hereinafter referred to as “Advanced payments”) to the Company.
                  </li>
                  <li>
                    Additionally, to the above-mentioned condition, the Advanced Payments must be accepted by the
                    Company, and acceptance of the Advanced Payment must be notified to the Renter by the Valid Forms of
                    Contact
                  </li>
                </ul>
              </li>
              <li>
                Standards of Time and Date Used
                <ul>
                  <li>
                    The Company operates in Japan Standard Time, and the Company hires Japan Standard Time as a standard
                    for all activities involved.
                  </li>
                  <li>
                    The rental period commences at 0:01 JST on the starting date and expires at 23:59 JST on your return
                    date.
                  </li>
                </ul>
              </li>
              <li>
                Cancellation of Rental Agreement
                <ul>
                  <li>
                    In the event of cancellation of the Rental Agreements, the Renter shall pay a cancellation fee to
                    the Company as stipulated below unless the Renter is reasonably irresponsible for the cause.
                    <ul>
                      <li>
                        Before we send the shipping confirmation email: No cancellation charge
                      </li>
                      <li>
                        After the shipping confirmation email: Non-refundable
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Company shall make refunds, if occurred, within a reasonable time after the cancellation of the
                    Rental Agreement.
                  </li>
                </ul>
              </li>
              <li>
                Changes to Rental Agreement
                <ul>
                  <li>
                    When the Renter desires to change the Renting Conditions as set forth in Article 3.1 after the
                    execution of the Rental Agreement, the Renter shall obtain the consent of the Company beforehand.
                  </li>
                  <li>
                    The Company may not consent to changes in Renting Conditions as described under Article 2.1 if such
                    changes interfere with the Company’s day-to-day renting operations.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Delivery of the Rental Equipment
            <ol class="inherit">
              <li>
                Limited Liability of Delivery
                <ul>
                  <li>
                    The Company will make every effort to ensure equipment reaches the Renter on time and in correct
                    working order. However, the Company is not liable for the late due to circumstances beyond the
                    reasonable control of the Company.
                  </li>
                </ul>
              </li>
              <li>
                The Renter’s Responsibilities on Delivery
                <ul>
                  <li>
                    Upon receipt of the Rental Equipment, the Renter is responsible to inspect whether the equipment is
                    in good, functional condition at the time of receipt.
                  </li>
                  <li>
                    In the case of defective equipment, the Renter must contact the Company immediately for
                    instructions. Without immediate notification to the Company, the Renter agrees that the equipment is
                    in good, functional condition at the time of receipt.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Use of the Rental Equipment
            <ol class="inherit">
              <li>
                Managerial Responsibilities of the Renter
                <ul>
                  <li>
                    The Renter and/or the User shall observe the duties with the care of a good custodian in operating
                    and maintaining the Rental Equipment, during the rental period from the delivery to the return of
                    the Rental Equipment to the Company (hereinafter referred to as “during the period of use of the
                    Rental Equipment”).
                  </li>
                </ul>
              </li>
              <li>
                Daily Inspections and Maintenance
                <ul>
                  <li>
                    The Renter shall conduct necessary daily inspection and maintenance of the Rental Equipment before
                    use. In case of defective Equipment, the Renter must contact the Company immediately for
                    instructions.
                  </li>
                </ul>
              </li>
              <li>
                Prohibited Acts
                <ul>
                  <li>
                    The Renter or the User shall be prohibited from any of the following acts during the rental period.
                  </li>
                  <li>
                    To use the Rental Equipment in violation of the laws or against public order and standards of
                    decency.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Return of the Equipment
            <ol class="inherit">
              <li>
                Responsibility of the Renter or for the Return of the Rental Equipment.
                <ul>
                  <li>
                    The Renter or The User shall return to the Company the Rental Equipment at the specified returning
                    place at or before the termination of the rental period.
                  </li>
                  <li>
                    The Renter or The User shall indemnify the Company for all the damage the Renter or the User caused
                    to the Company if the Renter or the User is in violation of Article 5.1.
                  </li>
                </ul>
              </li>
              <li>
                Check of the Rental Equipment at the Time of Return
                <ul>
                  <li>
                    The Renter or the user shall return the Rental Equipment in the same conditions as confirmed at the
                    commencement of the rental, except for ordinary wear and tear of the Rental Equipment arising from
                    the normal use, in the presence of a representative of the Company.
                  </li>
                </ul>
              </li>
              <li>
                Measures in Case of Non-Return of the Rental Equipment
                <ul>
                  <li>
                    In the event that the Renter does not return the Rental Equipment to the specified returning place
                    after the expiration of the rental period, and if the Renter fails to comply with the Company’s
                    request for return, or the whereabouts of the Renter is not known to the Company, or when due to
                    other circumstances the Company judges that the Rental Equipment is “non-returnable”, the Company
                    may take all possible legal measures, including, but not limited to, filing a criminal suit against
                    the Renter.
                  </li>
                  <li>
                    In case of non-return of the Rental Equipment as set forth in Article 6.3, the Renter shall be
                    liable for all the damages to the Company as set forth in article 8 and, additionally, shall bear
                    all the expenses the Company may have to incur for the collection of the Rental Equipment and for
                    the search of whereabouts of The Renter or The User.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            IN CASE OF BREAKDOWNS, ACCIDENTS, THEFT, OR OTHER CAUSES
            <ol class="inherit">
              <li>
                Measures in Case of Breakdowns
                <ul>
                  <li>
                    When the Renter detects any abnormality or breakdown of the Rental Equipment, during the period of
                    use of the Rental Equipment, the Renter shall immediately discontinue the operation and contact the
                    Company and at the same time follow the instructions given by the Company.
                  </li>
                </ul>
              </li>
              <li>
                Measures in Case of Theft
                <ul>
                  <li>
                    If the Rental Equipment is lost or stolen, and/or the Renter fails to return the Rental Equipment at
                    all, the Renter is responsible for the damages and expenses caused related to the loss of the Rental
                    Equipment.
                  </li>
                </ul>
              </li>
              <li>
                Termination of the Rental Agreement due to Non-Usability of the Rental Equipment
                <ol class="alpha">
                  <li>
                    In the event that the Renter can not continue using the Rental Equipment due to breakdowns, theft,
                    or any other cause (hereinafter referred to as “Non-Usability”) during the period of use of the
                    Rental Equipment, the Rental Agreement shall terminate.
                  </li>
                  <li>
                    The Renter or The User shall bear the costs for collection and repair of the Rental Equipment and
                    others incidental thereto and the Company shall not return to the Renter the Rental Charge received
                    except where the Non-Usability comes under any of the causes stipulated in Articles 7.3C or 7.3D
                  </li>
                  <li>
                    In the event that the Rental Equipment has been defective due to any cause existing prior to its
                    delivery to the Renter, the new Rental Agreement shall be deemed to have been executed
                  </li>
                  <li>
                    In the events that the Renter chooses not to accept the new Rental Agreement under Article 7.3C or
                    that the Company fails to offer to rent the Substitute, the Company shall return to the Renter the
                    Rental Charge received in full.
                  </li>
                  <li>
                    In the event that the Non-Usability arises from causes not attributed to either of the Renter and
                    the Company, the Company shall return to the Renter the Rental Charge received deducting a portion
                    of the Rental Charge which corresponds to the rental period from the commencement of rental to the
                    termination of rental.
                  </li>
                  <li>
                    Except for the measure set forth in Article 7.3, the Renter shall make no other claims to the
                    Company with respect to the damage or loss arising out of the Non-Usability of the Rental Equipment.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Indemnity and Compensation
            <ol class="inherit">
              <li>
                Liability
                <ul>
                  <li>
                    In the event that the Renter causes damage to a third party or the Company during the period of use
                    of the Rental Equipment, The Renter or The User shall be liable for indemnifying such damage except
                    for damages arising out of causes attributed to the Company.
                  </li>
                </ul>
              </li>
              <li>
                Compensation
                <ul>
                  <li>
                    Among the damages to the Company as described under Article 8.1, the Renter shall compensate the
                    Company, as separately provided in the price list, for the damages resulting from Non-Usability of
                    the Rental Equipment due to theft or breakdowns for which the Renter is responsible, which is
                    usually known as NOC (Non-Operation Charge).
                  </li>
                </ul>
              </li>
              <li>
                Price List
                <ol class="alpha">
                  <li>
                    <span class="bold">Delay</span><br/>
                    Late returns will be charged for full retail price for the plan minimum extension period. Late
                    fee will be charged until the day the Company receives the Equipment.
                  </li>
                  <li>
                    <span class="bold">LOST/Damage/Repair (returned in a different condition)</span><br/>
                    <span class="bold">Pocket WIFI</span><br/>
                    Pocket WIFI device: 30,000 JPY (including NOC)
                    Pocket WIFI (Screen only): 10,000 JPY
                    Pocket WIFI (Back cover only): 2,000 JPY
                    AC adapter: 2,000 JPY
                    USB cable: 1,000 JPY
                    Portable case: 1,000 JPY
                    Extra portable battery: 5,000 JPY<br/>
                    <span class="bold">Home WIFI</span><br/>
                    Home WiFi device: 30,000 JPY (including NOC)
                    AC adaptor: 1,000 JPY
                    Ethernet cable: 500 JPY
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Ownership of Equipment
            <ul>
              <li>
                The Renter agrees and acknowledges that the Company retains all ownership of the Equipment, including
                but not limited to user guides and accessories.
              </li>
            </ul>
          </li>
        </ol>
        <!-- Rental -->
      </div>
    </div>

    <ng-container *ngIf="
      (subcriptionType$ |async) === 'wifi' || (subcriptionType$ |async) === 'voice_wifi' || (subcriptionType$ |async) === 'voice_esim_wifi' || (subcriptionType$ |async) === 'home_wifi'
    ">
      <div class="container__terms__conditions pocketWifi">
        <h4 class="term__title">Pocket WiFi & Home WiFi damage/loss fees</h4>
        <div class="term__contents">
          <div class="term__contents--info">
            <p class="term__contents__title">Pocket WiFi</p>
            <table>
              <tr>
                <th>Item</th>
                <th>Fee</th>
              </tr>
              <tr>
                <td>Pocket WiFi device</td>
                <td>¥30,000</td>
              </tr>
              <tr>
                <td>Pocket WiFi screen</td>
                <td>¥10,000</td>
              </tr>
              <tr>
                <td>Pocket WiFi back cover</td>
                <td>¥2,000</td>
              </tr>
              <tr>
                <td>AC adaptor</td>
                <td>¥2,000</td>
              </tr>
              <tr>
                <td>USB cable</td>
                <td>¥1,000</td>
              </tr>
              <tr>
                <td>Portable case</td>
                <td>¥1,000</td>
              </tr>
              <tr>
                <td>Extra portable battery</td>
                <td>¥5,000</td>
              </tr>
            </table>
          </div>
          <br/>
          <div class="term__contents--info">
            <p class="term__contents__title">Home WiFi</p>
            <table>
              <tr>
                <th>Item</th>
                <th>Fee</th>
              </tr>
              <tr>
                <td>Home WiFi device</td>
                <td>¥30,000</td>
              </tr>
              <tr>
                <td>AC adaptor</td>
                <td>¥1,000</td>
              </tr>
              <tr>
                <td>Ethernet cable</td>
                <td>¥500</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container__terms__conditions period">
      <h4 class="term__title">Termination conditions and Payment cycle</h4>
      <div class="term__contents">
        <p class="term__contents__title">Termination conditions</p>
        <p class="term__contents__info">
          Terminations can only occur on the last day of a month.<br/>
          It is necessary to submit a termination form through your My Page by the 25th of your preferred termination month.<br/>
          Only returning the rental items will not terminate your contract.
        </p>
        <p class="term__contents__title">Payment cycle</p>
        <p class="term__contents__info">
          The monthly payment cycle is post-paid.<br/>
          Monthly usage fees are invoiced at the end of the following month and payment is required after this.
        </p>
      </div>
    </div>
    <ng-container *ngIf="(subcriptionType$ |async) === 'voice'
    || (subcriptionType$ |async) === 'data'
    || (subcriptionType$ |async) === 'voice_wifi'
    || (subcriptionType$ |async) === 'voice_esim_wifi'
    ">
      <div class="container__terms__conditions requirement">
        <h4 class="term__title">Device requirement (for SIM card contracts)</h4>
        <div class="term__contents">
          <p class="term__contents__info">Your device needs to be SIM-free in order to use Sakura Mobile SIM card.</p>
          <p class="term__contents__info">Please make sure that your device is unlocked. Sakura Mobile has no liability
            for unlocking your device.</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(paymentMethod$ |async) === 'SMARTPIT'">
      <div class="container__terms__conditions deposit">
        <h4 class="term__title">Deposit conditions</h4>
        <div class="term__contents">
          <p class="term__contents__info">※ For this payment method, deposits are required as below.<br>
            Voice SIM : 20,000 JPY <br>
            Data SIM / Home WiFi / Pocket WiFi : Amount equal to 2 months of payments.</p>
          <p class="term__contents__info">※ Upon termination of the contract, a part of the deposit will be returned to
            a bank account in Japan depending on the product.</p>
        </div>
      </div>
    </ng-container>

    <div class="container__checkbox__group">
      <div class="checkbox">
        <mat-checkbox class="checkbox__content" [ngClass]="{'error-not-checked': isChecked1}" (change)="checkbox1Change($event)" #checkbox1>I agree to Terms and Conditions of Service/Rental Agreement.</mat-checkbox>
        <div class="mat-checkbox-error" *ngIf="isChecked1">
          <span>Please check</span>
        </div>
      </div>
      <ng-container *ngIf="
        (subcriptionType$ |async) === 'wifi' || (subcriptionType$ |async) === 'voice_wifi' || (subcriptionType$ |async) === 'voice_esim_wifi' || (subcriptionType$ |async) === 'home_wifi'
      ">
        <div class="checkbox">
          <mat-checkbox class="checkbox__content" [ngClass]="{'error-not-checked': isChecked2}" (change)="checkbox2Change($event)" #checkbox2>I agree to Pocket WiFi & Home WiFi damage/loss fees.</mat-checkbox>
          <div class="mat-checkbox-error" *ngIf="isChecked2">
            <span>Please check</span>
          </div>
        </div>
      </ng-container>
      <div class="checkbox">
        <mat-checkbox class="checkbox__content" [ngClass]="{'error-not-checked': isChecked3}" (change)="checkbox3Change($event)" #checkbox3>I agree with the termination conditions and payment cycle.</mat-checkbox>
        <div class="mat-checkbox-error" *ngIf="isChecked3">
          <span>Please check</span>
        </div>
      </div>
      <ng-container *ngIf="(subcriptionType$ |async) === 'voice'
     || (subcriptionType$ |async) === 'data'
     || (subcriptionType$ |async) === 'voice_wifi'
     || (subcriptionType$ |async) === 'voice_esim_wifi'
     ">
        <div class="checkbox">
          <mat-checkbox class="checkbox__content" [ngClass]="{'error-not-checked': isChecked4}" (change)="checkbox4Change($event)" #checkbox4>I understand SakuraMobile has no liability for unlocking my
            phone and I have checked that my phone is unlocked.</mat-checkbox>
          <div class="mat-checkbox-error" *ngIf="isChecked4">
              <span>Please check</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(paymentMethod$ |async) === 'SMARTPIT'">
        <div class="checkbox">
          <mat-checkbox class="checkbox__content" [ngClass]="{'error-not-checked': isChecked5}" (change)="checkbox5Change($event)" #checkbox5>I agree to the deposit conditions.</mat-checkbox>
          <div class="mat-checkbox-error" *ngIf="isChecked5">
            <span>Please check</span>
          </div>
        </div>
      </ng-container>
    </div>
    <button class="container__submit submitted" (click)="submitNextStep()">next step</button>
  </div>
</div>
